export class News {
   public  pid: number
   public psize: number
   public blogId: number
   public name: string
   public name2: string
   public  title: string
   public  title2: string
   public actionDate: string
   public  userPhoto: string
   public blogPhoto: string
   public blogPhoto2: string
   public  coverPhoto: string
   public coverPhoto2: string
   public userPhotoBase64: string
   public blogPhotoBase64: string
   public blogPhoto2Base64: string
   public coverPhotoBase64: string

   public orderNo: number
   public text: string
   public   text2: string
   public tags: Tag[]
    public tags2: Tags2[]
    public  auditLog: AuditLog
    public date: string
    public  user: string
    public photo: string
    public  tagStr: string
    public  readCount: number
    public  isSpecial: boolean
    public   isArchive: boolean
    constructor(newsStr: any) {
        this.pid = newsStr.pid;
        this.psize = newsStr.psize;
        this.blogId = newsStr.blogId;
        this.name = newsStr.name;
        this.name2 = newsStr.name2;
        this.title = newsStr.title;
        this.title2 = newsStr.title2;
        this.actionDate = newsStr.actionDate;
        this.userPhoto = newsStr.userPhoto;
        this.blogPhoto = newsStr.blogPhoto;
        this.blogPhoto2 = newsStr.blogPhoto2;
        this.coverPhoto = newsStr.coverPhoto;
        this.coverPhoto2 = newsStr.coverPhoto2;
        this.text = newsStr.text;
        this.text2 = newsStr.text2;
        this.tags = newsStr.tags;
        this.tags2 = newsStr.tags2;
        this.auditLog = newsStr.auditLog;
        this.date = newsStr.date;
        this.user = newsStr.user;
        this.photo = newsStr.photo;
        this.tagStr = newsStr.tagStr;
        this.orderNo = newsStr.orderNo;
        this.readCount = newsStr.readCount;
        this.isSpecial = newsStr.isSpecial;
        this.isArchive = newsStr.isArchive;
        this.blogPhotoBase64 = newsStr.blogPhotoBase64;
        this.blogPhoto2Base64 = newsStr.blogPhoto2Base64;
        this.coverPhotoBase64 = newsStr.coverPhotoBase64;
        this.userPhotoBase64 = newsStr.userPhotoBase64;

    }
  }
  
  export class Tag {
    public name: string
    constructor(str: any) {
        this.name = str.name;
    }
  }
  
  export class Tags2 {
    public name: string
    constructor(str: any) {
        this.name = str.name;
    }
  }
  export class Banner {
    bannerId: number
    name: string
    link: string
    linkBase64: string
    deeplink: string
    auditLog: AuditLog
    constructor(newsStr: any) {
        this.bannerId = newsStr.bannerId;
        this.link = newsStr.link;
        this.linkBase64 = newsStr.linkBase64;
        this.name = newsStr.name;
        this.deeplink = newsStr.deeplink;
        this.auditLog = newsStr.auditLog;
    }
  }
  
  export class AuditLog {
    public  createdBy: number
    public   createdAt: string
    public   modifiedBy: number
    public   modifiedAt: string
    constructor(str: any) {
        this.createdBy = str.createdBy;
        this.createdAt = str.createdAt;
        this.modifiedBy = str.modifiedBy;
        this.modifiedAt = str.modifiedAt;

    }
  }
  export class ReportModel {
    public pid: number
    public psize: number
    public reportId: number
    public reportName: string
    public reportYear: number
    public reportType: string
    public filePath: string
    public order: number
    public isArchive: boolean
    public fileBase64: string
    constructor(newsStr: any) {
         this.pid = newsStr.pid;
         this.psize = newsStr.psize;
         this.reportId = newsStr.reportId;
         this.reportName = newsStr.reportName;
         this.reportYear = newsStr.reportYear;
         this.reportType = newsStr.reportType;
         this.filePath = newsStr.filePath;
         this.order = newsStr.order;
         this.isArchive = newsStr.isArchive;
         this.fileBase64 = newsStr.fileBase64;
     }
   }
   export class RuleModel {
    public pid: number
    public psize: number
    public ruleId: number
    public ruleName: string
    public ruleType: string
    public filePath: string
    public order: number
    public isArchive: boolean
    public fileBase64: string
    constructor(newsStr: any) {
         this.pid = newsStr.pid;
         this.psize = newsStr.psize;
         this.ruleId = newsStr.ruleId;
         this.ruleName = newsStr.ruleName;
         this.ruleType = newsStr.ruleType;
         this.filePath = newsStr.filePath;
         this.order = newsStr.order;
         this.isArchive = newsStr.isArchive;
         this.fileBase64 = newsStr.fileBase64;
     }
   }

   export class ReportList{
    public reportYear : number;
    public reports :Array<ReportModel> = [];
    constructor(newStr: any){
        this.reportYear = newStr.reportYear;
        for (let i = 0; i < newStr.reports.length; i++) {
          let blog = new ReportModel(newStr.reports[i]);
          this.reports.push(blog);
      }
    }
}
  