<nz-layout class="layout">
    <nz-sider class ="sider" nzCollapsible [nzCollapsed]="isCollapsed" [nzTrigger]="null">
        <div class="logo">
            <img src="assets/zeely/logo.png" alt="Logo" style="width: 20%;height: 20%;display: block;
            margin-left: auto;
            margin-right: auto; padding-top: 20px; " />
        </div >
      <ul nz-menu nzTheme="dark" nzMode="inline">
        <li nz-menu-item routerLinkActive="active">
            <a routerLink="/ap/news">
                <i nz-icon nzType="audit"></i>
                <span>Мэдээ</span>
            </a>
        </li>
        <li nz-menu-item routerLinkActive="active">
            <a routerLink="/ap/banner">
                <i nz-icon nzType="form"></i>
                <span>Баннер</span>
            </a>
        </li>
        <li nz-menu-item routerLinkActive="active">
            <a routerLink="/ap/report">
                <i nz-icon nzType="comment"></i>
                <span>Тайлан</span>
            </a>
        </li>
        <li nz-menu-item routerLinkActive="active">
            <a routerLink="/ap/rule">
                <i nz-icon nzType="comment"></i>
                <span>Дүрэм журам</span>
            </a>
        </li>
    </ul>
    </nz-sider>
    <nz-layout>
      <nz-header style="background-color: #0e0f0f;">
        <span
          class="trigger"
          nz-icon style="color: rgb(224, 224, 224);"
          [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          (click)="isCollapsed = !isCollapsed"
        ></span>
      </nz-header>
      <nz-content style="background-color: #ffffff; margin-left: 10px;margin-right: 10px;margin-top: 10px;">
        <div class="inner-content"><router-outlet></router-outlet></div>
      </nz-content>
    </nz-layout>
  </nz-layout>