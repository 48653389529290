<!--====== Service info START ======-->

<section id="about" class="about-area mt-130 mb-150">
  <div class="container about-area pb-40">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">Нууцлалын бодлого</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pb-80">
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <div class="single-testimonial">
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            1. Харилцагчийн мэдээллийг цуглуулах үндэслэл, зорилго
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul>
              <li>
                1.1 “Хатан суудал инвест” ББСБ (цаашид “Мэдээлэл хариуцагч” гэх)
                нь цахим зээлийн үйлчилгээний zeely.mn сайт болон zeely гар
                утасны аппликэйшн (цаашид “Zeely” гэх)-д бүртгүүлсэн
                харилцагчийн хувийн мэдээлэлд үндэслэн зээлдэгчийг таньж мэдэх
                болон харилцагчийн зээл авах хүсэлтийг баталгаажуулан онлайн
                зээлийн үйлчилгээг үзүүлнэ.
              </li>
              <li>
                1.2 Zeely-д нэвтэрч, өөрийн бүртгэлийг үүсгэн хэрэглээний зээл
                авах гэж буй 18 насанд хүрсэн Монгол Улсын иргэнийг Харилцагч
                гэнэ.
              </li>
              <li>
                1.3 Мэдээлэл хариуцагч нь харилцагчийг таньж мэдэх болон
                онлайнаар зээл авах хүсэлтийг баталгаажуулах зорилгоор
                харилцагчаас цахимаар олгосон зөвшөөрлийг үндэслэн хувийн
                мэдээллийг цуглуулж, боловсруулна.
              </li>
              <li>
                1.4 Харилцагч нь Zeely-д бүртгүүлэхээс өмнө Үйлчилгээний нөхцөл
                болон Нууцлалын бодлоготой бүрэн танилцаж, хувийн мэдээллийг
                цуглуулах, боловсруулахыг зөвшөөрсөн буюу зөвшөөрсөн хэсгийг
                “чагтлах” идэвхтэй үйлдлийг хийснийг Мэдээлэл хариуцагчид
                цахимаар зөвшөөрөл олгосон гэж үзнэ.
              </li>
              <li>
                1.5 Харилцагч нь Zeely-д үнэн зөв мэдээлэл оруулах үүрэгтэй,
                худал мэдээлэл оруулснаас үүдэж зээлийн үйлчилгээ авах боломжгүй
                болох эрсдэл болон Мэдээлэл хариуцагчид учирсан хохирлыг
                харилцагч бүрэн хариуцна.
              </li>
              <li>
                1.6 Мэдээлэл хариуцагч нь харилцагчийг таньж мэдэх болон зээл
                авах хүсэлтийг баталгаажуулах зорилгоор дараах мэдээллийг
                цуглуулж, боловсруулна. Үүнд:
              </li>
              <li style="padding-left: 50px">
                1.6.1 Zeely-д бүртгүүлэхэд ашиглаж буй харилцагчийн гар утасны
                дугаар, регистрийн дугаар.
              </li>
              <li style="padding-left: 50px">
                1.6.2 Харилцагчийг баталгаажуулах зорилгоор бүртгүүлсэн гар
                утасны дугаарт нууц үгийг илгээх бөгөөд тухайн нууц үгийг
                Zeely-д оруулснаар бүртгэл үүснэ. Харилцагчийн Zeely-д үүсгэсэн
                нууц үгийн шифрлэсэн код.
              </li>
              <li style="padding-left: 50px">
                1.6.3 Онлайн зээлийн үйлчилгээнд хамрагдахын тулд харилцагчийн
                Zeely-д оруулж буй овог, нэр, РД, утасны дугаар, гэрийн хаяг,
                ажил байдлын мэдээлэл, санхүүгийн мэдээлэл.
              </li>
              <li style="padding-left: 50px">
                1.6.4 Харилцагчийн Zeely-ээр авсан онлайн зээл, түүний эргэн
                төлөлтийн мэдээлэл.
              </li>
              <li style="padding-left: 50px">
                1.6.5 Zeely-ийг хэрэглэх үед харилцагчийн гар утасны камер руу
                хандах зөвшөөрлийг тусгайлан асууна. Харилцагч нь сэтгэл зүйн
                асуулгад суурилсан зээлийн скорингийн асуултад хариулж байх үед
                харилцагч өөрөө хариулж буйг баталгаажуулах зорилгоор 3 удаа
                авсан зураг.
              </li>
              <li>
                1.7 Мэдээлэл хариуцагч нь энэ бодлогын 1.6-д зааснаас өөр
                харилцагчийн хувийн мэдээллийг цуглуулж, боловсруулж, хадгалахыг
                хориглоно.
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            2. Харилцагчийн мэдээллийг боловсруулах, ашиглах
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul>
              <li>
                2.1 Мэдээлэл хариуцагч нь харилцагчийн мэдээллийг зөвхөн онлайн
                зээлийн үйлчилгээг үзүүлэх хүрээнд дараах байдлаар боловсруулж,
                ашиглана. Үүнд:
              </li>
              <li style="padding-left: 50px">
                2.1.1 Zeely-д нэвтрэх нууц үгийг зөвхөн харилцагч мэдэх бөгөөд
                харилцагчийн оруулсан нууц үгийг шифрлэн кодчилж хадгална.
              </li>
              <li style="padding-left: 50px">
                2.1.2 Сэтгэл зүйн асуулгад суурилсан зээлийн скорингийг бөглөж
                байхад цуглуулсан харилцагчийн зургийг зөвхөн харилцагчийг
                баталгаажуулах зорилгоор ашиглана.
              </li>
              <li style="padding-left: 50px">
                2.1.3 Харилцагчийн мэдээллийг онлайн зээлийн үйлчилгээг
                харилцагчид үзүүлэхэд ашиглана.
              </li>
              <li style="padding-left: 50px">
                2.1.4 Онлайн зээлийн үйлчилгээний чанар, хүртээмжийг сайжруулах,
                шинэ бүтээгдэхүүн ба модуль хөгжүүлэлт хийхэд зориулж дотоод
                хэрэгцээнд ашиглана.
              </li>
              <li style="padding-left: 50px">
                2.1.5 Zeely-ийн програм хангамжийг хөгжүүлэхэд ашиглах, энэхүү
                зорилгоор програм хөгжүүлэгчид мэдээллийг шилжүүлж, ашиглуулна.
              </li>
              <li style="padding-left: 50px">
                2.1.6 Мэдээллийн технологи, хиймэл оюун ухаан, тестийн
                тусламжтайгаар дүн шинжилгээ хийх, үнэн зөвийг шалгаж
                баталгаажуулах, хэрэглэгчийн санхүүгийн чадавхыг тогтоох
                зорилгоор ашиглана.
              </li>
              <li style="padding-left: 50px">
                2.1.7 Харилцагчид шинэ бүтээгдэхүүн, урамшууллын мэдээлэл,
                зээлийн төлбөр шилжүүлэх, зээлийн эргэн төлөлттэй холбоотой
                мэдээллийг хүргэх зориулалтаар ашиглана.
              </li>
              <li>
                2.2 Мэдээлэл хариуцагч нь харилцагчийн хувийн мэдээллийг Банк
                бус санхүүгийн үйл ажиллагааны тухай хуулийн 12.3 дахь хэсэг,
                Банкны тухай хуулийн 7.2 дахь хэсэгт зааснаас бусад тохиолдолд
                төрийн байгууллага болон гуравдагч этгээдэд гаргаж өгөхгүй,
                харилцагчийн мэдээллийн нууцлалыг хадгална.
              </li>
              <li>
                2.3 Мэдээлэл хариуцагч нь харилцагчийн мэдээллийг ашиг олох
                зорилгоор гуравдагч этгээдэд борлуулах, шилжүүлэх, хуваалцахыг
                хориглоно.
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            3. Харилцагчийн мэдээллийг хадгалах
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul>
              <li>
                3.1 Мэдээлэл хариуцагч нь харилцагчийн Zeely-д оруулсан
                мэдээллийг өөрийн серверт хадгална. Харилцагч нь Zeely-д нэвтрэх
                өөрийн нэр, нууц үгийг бусдад задруулахгүй байх үүрэгтэй.
              </li>
              <li>
                3.2 Нэвтрэх эрхээ бусдад алдсанаас үүдэлтэй харилцагчид учирсан
                аливаа хохирлыг Мэдээлэл хариуцагч хариуцахгүй. Мэдээлэл
                хариуцагчаас харилцагчийн хандах эрхийг ашиглан мэдээлэлд
                нэвтрэх боломжгүй болохыг анхааруулж байна.
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            4. Харилцагчийн мэдээллийг удирдах, устгах
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul>
              <li>
                4.1 Харилцагч нь гэрээ байгуулсны дараа утасны дугаар, овог нэр,
                регистрийн дугаар зэрэг хувийн мэдээллийг өөрөө өөрчлөх
                боломжгүй байна. Харилцагч нь утасны дугаараа сольсон тохиолдолд
                Zeely-ээр дамжуулан утасны дугаар солих хүсэлтийг илгээх ба
                ажлын 3 хоногт харилцагчийн хүсэлтийг шийдвэрлэнэ.
              </li>
              <li>
                4.2 Харилцагч онлайн зээлийн түүхтэй бол түүний мэдээллийг
                устгахгүй.
              </li>
              <li>
                4.3 Харилцагч Zeely-д бүртгүүлснээс хойш 2 сарын хугацаанд
                зээлийн гэрээ байгуулаагүй тохиолдолд харилцагчийн мэдээллийг
                Архив, албан хэрэг хөтлөлтийн тухай хуульд заасан хугацаагаар
                хадгална.
              </li>
              <li>
                4.4 Харилцагч нь өөрийн хүсэлтээр бүртгэлээ цуцлуулах хүсэлт
                гаргасан тохиолдолд дахин Zeely-д бүртгүүлэх боломжгүй тул
                харилцагчийн утасны дугаар, регистрийн дугаараас бусад
                мэдээллийг устгана.
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            5. Нууцлалын бодлогын шинэчлэлт
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #424852;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul>
              <li>
                5.1 Мэдээлэл хариуцагч нь Нууцлалын бодлогод нэмэлт өөрчлөлт
                оруулах эрхтэй ба Нууцлалын бодлогод нэмэлт өөрчлөлт оруулсан
                тохиолдолд тухай бүр http://www.zeely.mn/ платформд нээлттэй
                нийтлэх бөгөөд Харилцагч нь уншиж танилцах үүрэг хүлээнэ.
              </li>
              <li>
                5.2 Харилцагчийн бүртгэл үүсгэх болон гэрээ хийгдэх үед хүчинтэй
                байсан Нууцлалын бодлогод нэмэлт, өөрчлөлт орсон тохиолдолд
                өөрчлөгдсөн Нууцлалын бодлогыг хүчинтэйд тооцно.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--====== Service info ======-->
