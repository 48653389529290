import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConstantsService } from './constants.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string | undefined;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private consts: ConstantsService
  ) { }
  loginUser(username: string, password: string): Observable<any> {
    var headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(username + ':' + password)))
    });
    const options: {
      headers?: HttpHeaders,
    } = {
      headers: headers,
    };
    return this.http.post<any>(
      this.consts.apiUrl(this.consts.signinStr),
      null, options);
  }
  logoutUser() {
    this.storage.RemoveAuthorizationData();
    this.router.navigateByUrl('/' + this.consts.adminPanelLinkStr);
  }
}