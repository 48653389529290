import { Component, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage.service';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  isVisibleMiddle = false;
  langTxt: any;
  constructor(
    private translateService: TranslateService,
    private storage: StorageService,
    private consts: ConstantsService,
  ) { }

  ngOnInit(): void {
    if (this.storage.GetLang() == this.consts.langMnStr) this.langTxt = 'EN';
    else this.langTxt = 'MN';
  }
  changeLang(lang: any): void {
    let lg;
    if (lang == 'EN') {
      lg = this.consts.langEnStr;
    } else {
      lg = this.consts.langMnStr;
    }
    this.storage.SetLang(lg);
    location.reload();
  }
  showModalMiddle(): void {
    this.isVisibleMiddle = true;
  }
  handleOkMiddle(): void {
    console.log('click ok');
    this.isVisibleMiddle = false;
  }
  handleCancelMiddle(): void {
    this.isVisibleMiddle = false;
  }

}