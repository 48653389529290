import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './models/admin';
import { ConstantsService } from './services/constants.service';
import { Banner, News, ReportModel, RuleModel } from './models/models';

@Injectable({ providedIn: 'root' })
export class AdminService {

  private userData = new BehaviorSubject<User>(new User(''));
  public user = this.userData.asObservable();
  // changeUserData() {
  //   this.userData.next(new User(this._localstorage.GetUserInfoData()));
  // }

  constructor(
    private consts: ConstantsService,
    private http: HttpClient,
  ) { }

  
  getNews(pid: number, psize: number): Observable<any> {
    var request = {
      "pid": pid,
      "psize": psize
    };
    return this.http.get<any>(this.consts.apiUrl(this.consts.newslist), { params: request });
  } 
  getNewsById(id : number): Observable<any> {
    return this.http.get<any>(this.consts.apiUrl(this.consts.news)+ '/' + id);
  }
  saveNews(data: News, mode: boolean): Observable<any> {
    if (!mode) {
      return this.http.post<any>(
        this.consts.apiUrl(this.consts.news), data);
    } else {
      return this.http.put<any>(
        this.consts.apiUrl(this.consts.news), data);
    }
  }
  deleteBlog(id: number): Observable<any> {
    return this.http.delete<any>(this.consts.apiUrl(this.consts.news)+ '/' + id);
  }
  getBanner(id: number): Observable<any> {
    return this.http.get<any>(this.consts.apiUrl(this.consts.banner)+ '/' + id);
  }
  getBanners(pid: number, psize: number): Observable<any> {
    var request = {
      "Pid": pid,
      "Psize": psize
    };
    return this.http.get<any>(this.consts.apiUrl(this.consts.banners), { params: request });
  }
  saveBanner(data: Banner, mode: boolean): Observable<any> {
    if (!mode) {
      return this.http.post<any>(
        this.consts.apiUrl(this.consts.banner), data);
    } else {
      return this.http.put<any>(
        this.consts.apiUrl(this.consts.banner), data);
    }
  }
  deleteBanner(id: number): Observable<any> {
    return this.http.delete<any>(this.consts.apiUrl(this.consts.banner)+ '/' + id);
  }
  getReports(pid: number, psize: number): Observable<any> {
    var request = {
      "pid": pid,
      "psize": psize
    };
    return this.http.get<any>(this.consts.apiUrl(this.consts.reports), { params: request });
  } 
  deleteReport(id: number): Observable<any> {
    return this.http.delete<any>(this.consts.apiUrl(this.consts.report)+ '/' + id);
  }
  saveReport(data: ReportModel, mode: boolean): Observable<any> {
    if (!mode) {
      return this.http.post<any>(
        this.consts.apiUrl(this.consts.report), data);
    } else {
      return this.http.put<any>(
        this.consts.apiUrl(this.consts.report), data);
    }
  }
  getReportById(id : number): Observable<any> {
    return this.http.get<any>(this.consts.apiUrl(this.consts.report)+ '/' + id);
  }
  getRules(pid: number, psize: number): Observable<any> {
    var request = {
      "pid": pid,
      "psize": psize
    };
    return this.http.get<any>(this.consts.apiUrl(this.consts.rules), { params: request });
  } 
  deleteRule(id: number): Observable<any> {
    return this.http.delete<any>(this.consts.apiUrl(this.consts.rule)+ '/' + id);
  }
  saveRule(data: RuleModel, mode: boolean): Observable<any> {
    if (!mode) {
      return this.http.post<any>(
        this.consts.apiUrl(this.consts.rule), data);
    } else {
      return this.http.put<any>(
        this.consts.apiUrl(this.consts.rule), data);
    }
  }
  getRuleById(id : number): Observable<any> {
    return this.http.get<any>(this.consts.apiUrl(this.consts.rule)+ '/' + id);
  }
}