import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit {
  dateNow: any;

  isCollapsed: any;

  constructor() { }

  ngOnInit(): void {
  }
  signOut() {
  }

}
