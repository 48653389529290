import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

@Directive({
    selector: '[appPlayOnViewport]'
})
export class PlayOnViewportDirective implements OnInit, OnDestroy {
    private observer: IntersectionObserver | undefined;

    constructor(private el: ElementRef, private youtubePlayerService: YouTubePlayer) { }

    ngOnInit() {
        this.observer = new IntersectionObserver(
            this.handleIntersection.bind(this),
            { rootMargin: '0px', threshold: 0.5 }
        );
        this.observer.observe(this.el.nativeElement);
    }

    ngOnDestroy() {
        this.observer!.unobserve(this.el.nativeElement);
    }

    handleIntersection(entries: IntersectionObserverEntry[]) {
        const entry = entries[0];
        if (entry.isIntersecting) {
            this.youtubePlayerService.playVideo();
        } else {
            this.youtubePlayerService.pauseVideo();
        }
    }
}
