<section id="about" class="about-area mt-130 mb-150">
  <div class="container about-area pb-40">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">Terms of service</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pb-80">
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <body>
          <div class="px60">
            <div class="py3">
              The terms of this service are a legally binding document that
              allows our company to receive and store your specific information
              and process it to grant the right to borrow. Therefore, it is
              recommended that you familiarize yourself with the terms of
              service and decide whether to accept it. By checking the terms of
              service box below and entering the verification code provided by
              our organization to your phone number under the specified
              procedure you are deemed to agree to the following terms of
              service.
            </div>
            <br />
            1. Enter your personal information accurately.
            <div class="px32">
              <div class="py3">
                1.1 Customer is obligated to enter their personal information
                accurately if asked.
              </div>
              <div class="py3">
                1.2 Zeely has a right to receive and store customer's personal
                information provided by the user for further use
              </div>
              <div class="py3">
                1.3. Our app uses camera permission when When user needs to
                upload profile picture When user needs to prove who they are
                using government issued passport Uploading any image is not
                enforced, users can choose if they are willing to upload
                pictures or not
              </div>
              <div class="py3">
                1.4 The loan amount and terms will be determined by the result
                of the loan amount calculated by the questionnaire and
                customer’s information.
              </div>
              <div class="py3">
                1.5 If the customer is completed the questionnaire and eligible
                for a loan, we will draft a loan contract for you using the
                information you provided.
              </div>
              <div class="py3">
                1.6 If the information provided by a customer has been proven to
                be false information, our company has a right to refuse to grant
                you a loan and the loan contract will be deemed void. In
                addition, the customer, will be legally responsible for any
                consequences or damages caused by the false information.
              </div>
              <div class="py3">
                1.7 To ensure the security of your information and to prevent
                unauthorized use of the Zeely app, our company will have access
                to your app activity and store it as a history log, and use it
                within the relevant law
              </div>
            </div>
            <div class="py3">
              2. Only after signing a contract, you will become a customer of
              our services.
            </div>
            <div class="py3">
              3. The terms of service are governed by the loan contract.
            </div>
            <div class="py3">
              4. The Zeely app reserves the right to change its terms of service
              and will notify you of such changes through its website, the app's
              introductory section, and the customer's mobile phone.
            </div>
            <div class="py3">
              5. If a decision is made not to grant a loan, you can contact our
              company again after a certain period and answer the questionnaire
              again.
            </div>
            <div class="py3">
              6. Customers are required to repay their loan amount with 5%
              Monthly Interest Rate under 60 days.
            </div>
            <br />
            <div class="py3">
              ACCEPTING THE TERMS OF SERVICE AND USING OUR APPLICATION, DOES NOT
              MAKE OUR COMPANY LIABLE TO PROVIDE ANY LOANS. FURTHERMORE, YOU ARE
              NOT ALLOWED TO MAKE ANY CLAIM AGAINST OUR COMPANY ON ANY OTHER
              ISSUE UNLESS SPECIFICALLY STATED IN THE TERMS OF SERVICE.
            </div>
            <br />
            <br />
            <br />
            <br />
            <div class="container about-area pb-40">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div
                    class="about-title text-center wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <h3 class="title">
                      <span style="color: #2d66b1">Privacy Policy</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              1. Rationale and purpose of collecting customer information
            </div>
            <br />
            <div>
              <div class="px32">
                1.1 Zeely recognizes the customer and confirms and provides
                online loan services at your request based on personal
                information registered on the zeely.mn e-loan service website
                and Zeely mobile application (hereinafter referred to as Zeely).
                <br />
                <br />
                1.2 A Customer is a Mongolian citizen who has reached the age of
                18 and is applying for a consumer loan by logging in to Zeely
                and creating an account. <br />
                <br />
                1.3 The Data Holder collects and processes personal information
                based on the customer's electronic consent to recognize the
                customer and approve the online loan application. <br />
                <br />
                1.4 The Customer is fully aware of the Terms of Service and the
                Privacy Policy before registering with Zeely, and by “checking
                the box” of the contract, he/she is fully agreeing to the
                collection and processing of information by the Data Holder.
                <br />
                <br />
                1.5 The Customer is obliged to provide accurate information to
                Zeely, and the Customer shall be fully responsible for the risk
                of not being able to access the loan service due to entering
                false information and will be responsible for any damages that
                occurred to the Data Holder.
                <br />
                <br />
                1.6 The respondent collects and processes the following
                information to recognize the customer and approve the loan
                application. These include:
                <br />
                <br />
                1.6.1 The customer's mobile phone number and registration number
                used to register with Zeely. <br />
                <br />
                1.6.2 To verify the customer, a password will be sent to the
                registered mobile phone number and the registration will be
                created by entering the password into Zeely. The encrypted
                password is created by the customer in Zeely. <br />
                <br />
                1.6.3 The customer's last name, first name, registration number,
                telephone number, home address, employment information, and
                financial information provided by the customer to Zeely to
                access the online loan service.
                <br />
                <br />
                1.6.4 Customer's online loan obtained through Zeely and its
                repayment information. <br />
                <br />
                1.7 The Data Holder is prohibited from collecting, processing,
                or storing personal information of customers other than those
                specified in 1.6 of this policy. <br />
                <br />
              </div>
              2. Processing and using customer information <br />
              <br />
              <div class="px32">
                2.1 The Data Holder shall process and use customer information
                only in the condiv of providing online credit services as
                follows. These include: <br />
                <br />
                2.1.1 The Zeely password is known only to the customer, and the
                password entered by the customer is encrypted and stored. <br />
                <br />
                2.1.2 Customer information will be used to provide online credit
                services to the customer. <br />
                <br />
                2.1.3 The information will be used internally to improve the
                quality and availability of online loan services and to develop
                new products and modules. <br />
                <br />
                2.1.4 The information will be used for the development of
                Zeely's software, for which purpose information, information is
                transferred and used by the developer. <br />
                <br />
                2.1.5 The information will be used for analysis, verification,
                and determining financial capability with the use of information
                technology, artificial intelligence, and tests. <br />
                <br />
                2.1.6 The information will be used to provide customers with
                information on new products, promotions, loan disbursements, and
                loan repayments.
                <br />
                <br />
                2.2 The Data Holder shall not disclose the customer's personal
                information to government organizations and third parties,
                except as provided in Article 12.3 of the Law on Non-Bank
                Financial Activities and Article 7.2 of the Banking Law of
                Mongolia, and shall keep the customer's information
                confidential. <br />
                <br />
                2.3 The Data Holder is prohibited from selling, transferring, or
                sharing customer information to third parties for profit. <br />
                <br />
              </div>
              3. Storing customer information <br />
              <br />
              <div class="px32">
                3.1 The Data Holder stores the information entered by the
                customer in Zeely on its server. The customer is obliged not to
                disclose the customer's username and password to others. <br />
                <br />
                3.2 The Data Holder shall not be liable for any damages caused
                to customers as a result of the loss of their login information.
                The Data Holder warns that access to the customer's access
                information will not be possible. <br />
                <br />
              </div>
              4. Managing and deleting customer information <br />
              <br />
              <div class="px32">
                4.1 The customer himself/herself will not be able to change
                personal information such as phone number, name, and
                registration number after signing the contract. If the
                customer’s phone number is changed, he/she can request to change
                the phone number through Zeely and the customer's request will
                be resolved within three (3) working days.
                <br />
                <br />
                4.2 If a customer has an online credit history, their
                information will not be deleted. <br />
                <br />
                4.3 If the customer does not sign a loan contract within 2
                months of registering with Zeely, the customer's information
                will be retained for the period specified in the Law on Archives
                and Record-Keeping.
                <br />
                <br />
                4.4 If the customer requests to cancel his/her registration,
                he/she will not be able to register at Zeely again. All
                information will be deleted except for the customer’s phone
                number and registration number. <br />
                <br />
              </div>
              5. Privacy Policy Reform <br />
              <br />
              <div class="px32">
                5.1 The Data Holder reserves the right to make changes and
                amendments to the Privacy Policy and in case of any amendments
                to the Privacy Policy, it will be published openly on the
                platform http://www.zeely.mn/ and the Customer will be obliged
                to read it.
                <br />
                <br />
                5.2 In the event of an amendment to the Privacy Policy that was
                in effect at the time of customer registration or contracting,
                the amended Privacy Policy shall be considered valid. <br />
                <br />
              </div>
            </div>
          </div>
        </body>
      </div>
    </div>
  </div>
</section>
