import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminService } from 'src/app/admin.service';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { News } from 'src/app/models/models';


@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.less']
})
export class NewsDetailComponent implements OnInit {
  formData!: FormGroup;
  inputValue = '';
  isLoading: boolean = true;
  isEdit : boolean = false;
  
  userPhotoUrl: string |undefined;
  coverPhotoUrl: string |undefined;
  blogPhotoUrl: string |undefined;
  blogPhotoUrl2: string |undefined;
  
  newsData : News|undefined;

  listOfOption: Array<{ value: string; label: string }> = [];
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  @ViewChild('editor') editor: AngularEditorComponent | undefined;

  constructor(
    private admin: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }
  selectedTagItems: string[] = []; 
  selectedTag2Items: string[] = []; 
  editorConfig: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '300px',
    customClasses: [
      {
        name: 'angular-editor-textarea',
        class: 'angular-editor-textarea',
      }
    ]
  };

  onSelectionTagChange(selectedItems: string[]): void {
    console.log(selectedItems);
    this.selectedTagItems = selectedItems;
  }
  onSelectionTag2Change(selectedItems: string[]): void {
    console.log(selectedItems);
    this.selectedTag2Items = selectedItems;
  }
  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    if (id == null) {
      this.isEdit = false;
       this.intiForm();
    } else {
      this.isEdit = true;
      this.initData();
      this.onLoadData(id);
    }
  }
  onLoadData(id: any){
    this.admin.getNewsById(id)
    .subscribe(
      async data => {
        this.newsData = data as News;
        this.formData.controls['blogId'].setValue(this.newsData.blogId);
        this.formData.controls['name'].setValue(this.newsData.name);
        this.formData.controls['name2'].setValue(this.newsData.name2);
        this.formData.controls['title'].setValue(this.newsData.title);
        this.formData.controls['title2'].setValue(this.newsData.title2);
        this.formData.controls['text'].setValue(this.newsData.text);
        this.formData.controls['text2'].setValue(this.newsData.text2);
        this.formData.controls['order'].setValue(this.newsData.orderNo);

        let tags2 :string[]=[];
        await this.newsData.tags2.forEach((x) => {
          tags2.push(x.name);
        });
        this.selectedTag2Items = tags2;
        let tags :string[]=[];

        await this.newsData.tags.forEach((x) => {
          tags.push(x.name);
        });
        this.selectedTagItems = tags;
        this.userPhotoUrl = this.newsData.userPhoto;
        this.coverPhotoUrl = this.newsData.coverPhoto;
        this.blogPhotoUrl = this.newsData.blogPhoto;
        this.blogPhotoUrl2 = this.newsData.blogPhoto2;
      },
      error => console.log(error + ' Error => getOurTeam()')
    );
  }
  intiForm(){
    this.formData = this.fb.group({
      // blogId: [undefined],
      name: [null, [Validators.required]],
      name2: [null],
      tags: [null],
      tags2: [null],
      order:[null,[Validators.required]],
      title:[null,[Validators.required]],
      title2:[null],
      text:[null,[Validators.required]],
      text2:[null],
      userPhotoBase64: [null],
      blogPhotoBase64: [null],
      blogPhoto2Base64: [null],
      coverPhotoBase64: [null],
    });
  }
  initData(){
    this.formData = this.fb.group({
      blogId: [undefined],
      name: [null, [Validators.required]],
      name2: [null],
      tags: [null],
      tags2: [null],
      order:[null,[Validators.required]],
      title:[null,[Validators.required]],
      title2:[null],
      text:[null,[Validators.required]],
      text2:[null],
      userPhotoBase64: [null],
      blogPhotoBase64: [null],
      blogPhoto2Base64: [null],
      coverPhotoBase64: [null],
    });
  }
  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }, type:string): void {
    this.getBase64(info.file!.originFileObj!, (file: string) => {
        switch (type){
          case 'user': 
            this.formData.controls['userPhotoBase64'].setValue(file)
            break;
          case 'detail': 
            this.formData.controls['blogPhotoBase64'].setValue(file)
            break;
          case 'detail2': 
            this.formData.controls['blogPhoto2Base64'].setValue(file)
            break;
          case 'photo': 
            this.formData.controls['coverPhotoBase64'].setValue(file)
            break;
        }        
      });
  }
  submit(){
    console.log(this.formData);
    console.log(this.formData.valid);
    let tags: {name: string }[] = [];
    let tags2: {name: string }[]=[];

    if(this.selectedTagItems.length >0){
      this.selectedTagItems.forEach((x) => {
        tags.push({ name: x });
    });
    }
    if(this.selectedTag2Items.length >0){
      this.selectedTag2Items.forEach((x) => {
        tags2.push({ name: x });
    });
    }
    this.formData.controls['tags'].setValue(tags);
    this.formData.controls['tags2'].setValue(tags2);


    if (this.formData.valid) {
      this.isLoading = true;
      let news = new News(this.formData.value);
      this.admin.saveNews(news, this.isEdit)
        .subscribe(
          data => {
            this.msg.success('Амжилттай хадгалагдлаа');
          },
          error => {
            this.msg.error(error.Message);
            console.log(error.Message + ' Error => saveNews()')
            console.log(error + ' Error => saveNews()')
            console.log(error.StatusCode + ' Error => saveNews()')
          }
        );
      this.isLoading = false;
    } else {
      Object.values(this.formData.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
