import { Component, OnInit } from '@angular/core';
import { Question, Answer } from 'src/app/models/scr';
import { ConstantsService } from 'src/app/services/constants.service';
import { PsyService } from 'src/app/services/psy/psy.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.less']
})
export class ScoringComponent implements OnInit {

  current = 0;
  page = 1;
  infoId = 0;
  resQuestions: any;
  questions: Array<Question> = [];
  resResults: any;
  loading = false;
  lg: any;
  isShown: boolean | undefined;

  index = 'First-content';

  constructor(
    private _psy: PsyService,
    private storage: StorageService,
    private consts: ConstantsService
  ) { }

  ngOnInit(): void {
    this.isShown = true;
    if (this.lg = this.storage.GetLang() == this.consts.langEnStr) {
      this.isShown = false;
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  start(): void {
    this.page = 2;
    this.loading = true;
    this._psy.getTest()
      .subscribe(
        data => {
          this.infoId = data.infoId;
          this.resQuestions = data.resQuestions;
          this.loading = false;
        },
        error => { console.log(error); }
      );
  }

  next(quesNo: number, ansNo: number, resQuestions: number): void {
    let ans = new Answer(ansNo, ansNo.toString());
    let ques = new Question(quesNo, ans);
    this.questions.push(ques);
    if (this.current == resQuestions - 1) {
      this.done(this.questions);
      this.page = 3;
    }
    this.current += 1;
  }

  done(ques: Array<Question>): void {
    this.loading = true;
    this._psy.setTest(this.infoId, ques)
      .subscribe(
        data => {
          this.resResults = data.resResults;
          this.loading = false;
        },
        error => { console.log(error); }
      );
  }
}