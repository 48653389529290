import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zeely',
  templateUrl: './zeely.component.html',
  styleUrls: ['./zeely.component.less']
})
export class ZeelyComponent implements OnInit {
  registerImages = ["assets/zeely/register1.png", "assets/zeely/register2.png", "assets/zeely/register3.png", "assets/zeely/register4.png"];

  constructor() { }

  ngOnInit(): void {
  }

}
