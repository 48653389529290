export class Answer {
    public AnsNo: number = 0;
    public Value: String = "";

    constructor(ansNo: number, value: String) {
        this.AnsNo = ansNo;
        this.Value = value;
    }
}

export class Question {
    public QuesNo: number = 0;
    public Secund: number = 5;
    public ReqAnswers: Array<Answer> = [];

    constructor(quesNo: number, ans: Answer) {
        this.QuesNo = quesNo;
        this.Secund = 5;
        this.ReqAnswers.push(ans);
    }
}