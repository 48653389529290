<!-- header hero -->

<div
  id="home"
  class="header-hero bg_cover d-lg-flex align-items-center"
  style="background-color: #0e0f0f"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="header-hero-content">
          <h4
            class="hero-title wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <b>{{ "home.main.title" | translate }}</b>
          </h4>
          <p
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
            style="color: white"
          >
            {{ "home.main.desc" | translate }}
          </p>
          <div class="gallery-btn mt-60">
            <a class="main-btn" routerLink="/zeely">{{
              "home.main.more" | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="header-hero-image d-flex align-items-center wow fadeInRightBig"
    data-wow-duration="1s"
    data-wow-delay="1.1s"
  >
    <div class="image">
      <img
        src="assets/zeely/man.png"
        style="
          width: 400px;
          height: 700px;
          margin-left: 20px;
          text-align: right;
        "
        alt="Hero Image"
      />
    </div>
  </div>
</div>

<!-- header hero -->

<!--====== Youtube player ======-->
<div
  class="container"
  style="margin-top: 20px; margin-bottom: 20px"
  #youTubePlayer
>
  <youtube-player
    videoId="MZgpr_yKDwU"
    [width]="videoWidth"
    [height]="videoHeight"
    [playerVars]="playerConfig"
    appPlayOnViewport
  >
  </youtube-player>
</div>

<!--====== OUR SERVICE PART START ======-->

<div class="container" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div class="col-lg-6">
      <div
        class="our-services-image mt-50 wow fadeInLeftBig"
        style="text-align: center"
        data-wow-duration="1s"
        data-wow-delay="0.8s"
      >
        <img src="assets/zeely/scoring.png" alt="service" />
      </div>
    </div>
    <div class="col-lg-6">
      <div
        class="our-services-content mt-150 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
          <span style="color: #2d66b1">01.</span
          ><span style="padding-left: 20px; color: #2d66b1">{{
            "home.main.content.scoring" | translate
          }}</span>
        </div>
        <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
          <div
            class="service-icon"
            style="margin-top: 7px; width: 18px; height: 18px"
          >
            <img src="assets/zeely/rectangle.png" alt="Icon" />
          </div>
          <div class="service-content media-body">
            <p style="line-height: 26px; color: white">
              {{ "home.main.content.scdesc" | translate }}
            </p>
          </div>
        </div>
        <!-- <div class="single-service d-flex" style="padding: 10px 20px 10px 0;">
                    <div class="service-icon" style="margin-top: 7px;">
                        <img src="assets/zeely/rectangle.png" alt="Icon">
                    </div>
                    <div class="service-content media-body">
                        <p style="line-height: 26px;color: #424852;">Match people from different places the same country
                            in
                            chatroom make friends with them.</p>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div class="col-lg-6">
      <div
        class="our-services-content mt-150 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
          <span style="color: #2d66b1">02.</span
          ><span style="padding-left: 20px; color: #2d66b1">{{
            "home.main.content.online" | translate
          }}</span>
        </div>
        <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
          <div
            class="service-icon"
            style="margin-top: 7px; width: 18px; height: 18px"
          >
            <img src="assets/zeely/rectangle.png" alt="Icon" />
          </div>
          <div class="service-content media-body">
            <p style="line-height: 26px; color: white">
              {{ "home.main.content.odesc" | translate }}
            </p>
          </div>
        </div>
        <!-- <div class="single-service d-flex" style="padding: 10px 20px 10px 0;">
                    <div class="service-icon" style="margin-top: 7px;">
                        <img src="assets/zeely/rectangle.png" alt="Icon">
                    </div>
                    <div class="service-content media-body">
                        <p style="line-height: 26px;color: #424852;">Match people from different places the same country
                            in
                            chatroom make friends with them.</p>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="col-lg-6">
      <div
        class="our-services-image mt-50 wow fadeInRightBig"
        style="text-align: center"
        data-wow-duration="1s"
        data-wow-delay="0.8s"
      >
        <img src="assets/zeely/online-cont.png" alt="service" />
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div class="col-lg-6">
      <div
        class="our-services-image mt-50 wow fadeInLeftBig"
        style="text-align: center"
        data-wow-duration="1s"
        data-wow-delay="0.8s"
      >
        <img src="assets/zeely/bichil-zeel.png" alt="service" />
      </div>
    </div>
    <div class="col-lg-6">
      <div
        class="our-services-content mt-150 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
          <span style="color: #2d66b1">03.</span
          ><span style="padding-left: 20px; color: #2d66b1">{{
            "home.main.content.bichil" | translate
          }}</span>
        </div>
        <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
          <div
            class="service-icon"
            style="margin-top: 7px; width: 18px; height: 18px"
          >
            <img src="assets/zeely/rectangle.png" alt="Icon" />
          </div>
          <div class="service-content media-body">
            <p style="line-height: 26px; color: white">
              {{ "home.main.content.bdesc" | translate }}
            </p>
          </div>
        </div>
        <!-- <div class="single-service d-flex" style="padding: 10px 20px 10px 0;">
                    <div class="service-icon" style="margin-top: 7px;">
                        <img src="assets/zeely/rectangle.png" alt="Icon">
                    </div>
                    <div class="service-content media-body">
                        <p style="line-height: 26px;color: #424852;">Match people from different places the same country
                            in
                            chatroom make friends with them.</p>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div class="col-lg-6">
      <div
        class="our-services-content mt-150 wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
          <span style="color: #2d66b1">04.</span
          ><span style="padding-left: 20px; color: #2d66b1">{{
            "home.main.content.surgalt" | translate
          }}</span>
        </div>
        <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
          <div
            class="service-icon"
            style="margin-top: 7px; width: 18px; height: 18px"
          >
            <img src="assets/zeely/rectangle.png" alt="Icon" />
          </div>
          <div class="service-content media-body">
            <p style="line-height: 26px; color: white">
              {{ "home.main.content.sdesc" | translate }}
            </p>
          </div>
        </div>
        <!-- <div class="single-service d-flex" style="padding: 10px 20px 10px 0;">
                    <div class="service-icon" style="margin-top: 7px;">
                        <img src="assets/zeely/rectangle.png" alt="Icon">
                    </div>
                    <div class="service-content media-body">
                        <p style="line-height: 26px;color: #424852;">Match people from different places the same country
                            in
                            chatroom make friends with them.</p>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="col-lg-6">
      <div
        class="our-services-image mt-50 wow fadeInLeftBig"
        style="text-align: center"
        data-wow-duration="1s"
        data-wow-delay="0.8s"
      >
        <img src="assets/zeely/zeely-learn.png" alt="service" />
      </div>
    </div>
  </div>
</div>

<!--====== OUR SERVICE PART ENDS ======-->

<!--====== SERVICE PART START ======-->

<div class="container about-area pt-150">
  <div class="row justify-content-center">
    <div class="col-lg-9">
      <div
        class="about-title text-center wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <h3 class="title">
          <span style="color: #2d66b1">{{
            "zeely.main.setgegdel.title" | translate
          }}</span>
        </h3>
      </div>
    </div>
  </div>
  <!-- row -->
  <div
    class="row testimonial-right-content mt-50 wow fadeIn"
    data-wow-duration="1s"
    data-wow-delay="0.6s"
  >
    <div class="col-lg-4">
      <div class="single-testimonial" style="height: 210px">
        <div class="d-sm-flex justify-content-between">
          <div class="author-info d-flex align-items-center">
            <div class="author-image">
              <img
                src="assets/zeely/dandar_erdenetuya.png"
                alt="author"
                width="40"
              />
            </div>
            <div class="testimonial-text" style="padding-left: 15px">
              <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                {{ "zeely.main.setgegdel.person.1.ner" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="testimonial-text" style="color: #fff; padding-top: 10px">
          {{ "zeely.main.setgegdel.person.1.comment" | translate }}
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="single-testimonial" style="height: 210px">
        <div class="d-sm-flex justify-content-between">
          <div class="author-info d-flex align-items-center">
            <div class="author-image">
              <img src="assets/zeely/moogii_moog.png" alt="author" width="40" />
            </div>
            <div class="testimonial-text" style="padding-left: 15px">
              <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                {{ "zeely.main.setgegdel.person.2.ner" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="testimonial-text" style="color: #fff; padding-top: 10px">
          {{ "zeely.main.setgegdel.person.2.comment" | translate }}
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="single-testimonial" style="height: 210px">
        <div class="d-sm-flex justify-content-between">
          <div class="author-info d-flex align-items-center">
            <div class="author-image">
              <img src="assets/zeely/genenkhuu.png" alt="author" width="40" />
            </div>
            <div class="testimonial-text" style="padding-left: 15px">
              <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                {{ "zeely.main.setgegdel.person.3.ner" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="testimonial-text" style="color: white; padding-top: 10px">
          {{ "zeely.main.setgegdel.person.3.comment" | translate }}
        </div>
      </div>
    </div>
  </div>
  <!-- testimonial right content -->
</div>
<!-- container -->

<!--====== BLOG START ======-->
<div class="container about-area pt-80 pb-80">
  <div
  class="row testimonial-right-content mt-20 wow fadeIn"
  data-wow-duration="1s"
  data-wow-delay="0.6s"
  *ngIf="blogDatas"
  >
  <div class="col-lg-4 col-sm-6 mb-20 my-custom-content-item {{  blog.tagStr }} "
    *ngFor="let blog of blogDatas?.BlogList | slice : 0 : 3" >
    <div class="blog-gallery gallery-masonry wow fadeIn">
      <div class="gallery-image">
        <img src="{{ blog.photo }}" alt="blog" height="464"/>
        <div class="blog-testimonial">
          </div>
          <div class="blog-card">
            <div class="testimonial-text" style="color: #fff; ">
              <a style="color: white; font-size: 16px; font-weight: 700;position: absolute; bottom: 115px;" 
                [routerLink]="[blogDetailLink, blog.blogId]"
                >{{ blog.title }}</a>
            </div>
              <span style="position: absolute; bottom: 79px;">{{"detail.main.niitelsen" | translate}}: {{
                blog.date | date : "yyyy.MM.dd"  }}</span>
                <a  [routerLink]="[blogDetailLink, blog.blogId]">
                  <div class="single-testimonial-span">
                    <span *ngFor="let tag of blog.tags">#{{ tag.name }}</span>
                    <div class="arrow">
                      ➜
                    </div>
                  </div>
                </a>
        </div>
      </div>
    
    </div>
  </div>
  </div>
</div>
 
<!-- <div class="container about-area pt-80 pb-80">
  <div class="row justify-content-center">
    <div class="col-lg-9">
      <div
        class="about-title text-center wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.4s"
      >
        <h3 class="title">
          <span style="color: #2d66b1">{{
            "detail.main.title" | translate
          }}</span>
        </h3>
      </div>
    </div>
  </div>
  <div
    class="row testimonial-right-content mt-20 wow fadeIn"
    data-wow-duration="1s"
    data-wow-delay="0.6s"
    *ngIf="blogDatas"
  >
    <div
      class="col-lg-4 mb-50"
      *ngFor="let blog of blogDatas.BlogList | slice : 0 : 3"
    >
      <img
        src="{{ blog.coverPhoto }}"
        alt="blog"
        width="370"
        height="247"
        style="border-radius: 15px 15px 0 0"
      />
      <div class="single-testimonial single-testimonial-radius">
        <div class="single-testimonial-span">
          <span style="color: white" *ngFor="let tag of blog.tags"
            >#{{ tag.name }}</span
          >
        </div>
        <div class="d-sm-flex justify-content-between">
          <div class="author-info d-flex align-items-center">
            <div class="author-image">
              <img src="{{ blog.user }}" alt="author" width="40" />
            </div>
            <div class="testimonial-text" style="padding-left: 15px">
              <p
                style="
                  color: #2d66b1;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 0.6;
                "
              >
                {{ blog.name }}
              </p>
              <span style="font-size: 12px; color: #a9b0bb">{{
                "detail.main.niitelsen" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="testimonial-text"
          style="color: white; padding-top: 10px; height: 60px"
        >
          <a
            style="color: white"
            [routerLink]="[blogDetailLink, blog.blogId]"
            >{{ blog.title }}</a
          >
        </div>
        <!-- <div class="testimonial-time"> -->
        <!-- <img src="assets/zeely/alarm-clock.png" alt="blog-time" width="28px" />
        <span style="margin-left: 10px; color: #a9b0bb">{{
          blog.date | date : "yyyy.MM.dd"
        }}</span>
      </div>
    </div>
  </div>
</div>  -->

<!--====== BLOG END ======-->
