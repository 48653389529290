import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./layouts/main/main.component";
import { AboutComponent } from "./pages/about/about.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { HelpComponent } from "./pages/help/help.component";
import { HomeComponent } from "./pages/home/home.component";
import { TuluyComponent } from "./pages/tuluy/tuluy.component";
import { ZeelyComponent } from "./pages/zeely/zeely.component";
import { DetailComponent } from "./pages/blog/detail/detail.component";
import { ScoringComponent } from "./pages/scoring/scoring.component";
import { ServiceinfoComponent } from "./pages/serviceinfo/serviceinfo.component";
import { PolicyComponent } from "./pages/policy/policy.component";
import { AccessibilityComponent } from "./pages/accessibility/accessibility.component";
import { InfosecComponent } from "./pages/infosec/infosec.component";
import { WhistleComponent } from "./pages/whistle/whistle.component";
import { PrivacyPolicyComponent } from "./pages/policy/privacy-policy/privacy-policy.component";
import { AdminComponent } from "./admin/admin.component";
import { AppGuard } from "./app-guard";
import { AuthComponent } from "./admin/auth/auth.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { SigninComponent } from "./admin/auth/signin/signin.component";
import { BannerComponent } from "./admin/banner/banner.component";
import { NewsComponent } from "./admin/news/news.component";
import { ReportComponent } from "./admin/report/report.component";
import { RuleComponent } from "./admin/rule/rule.component";
import { UserComponent } from "./admin/user/user.component";
import { NewsDetailComponent } from "./admin/news/news-detail/news-detail.component";

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "zeely",
        component: ZeelyComponent,
      },
      {
        path: "tuluy",
        component: TuluyComponent,
      },
      {
        path: "blog",
        component: BlogComponent,
      },
      {
        path: "blog/:id",
        component: DetailComponent,
      },
      {
        path: "about",
        component: AboutComponent,
      },
      {
        path: "info-sec",
        component: InfosecComponent,
      },
      {
        path: "whistle",
        component: WhistleComponent,
      },
      {
        path: "help",
        component: HelpComponent,
      },
      {
        path: "scoring",
        component: ScoringComponent,
      },
      {
        path: "service-info",
        component: ServiceinfoComponent,
      },
      {
        path: "policy",
        component: PolicyComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
    ],
  },
  {
    path: "",
    component: AdminComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: "ap",
        component: DashboardComponent,
      },
      {
        path: "ap/banner",
        component: BannerComponent,
      },
      {
        path: "ap/news",
        component: NewsComponent,
      },
      {
        path: "ap/news/add",
        component: NewsDetailComponent,
      },
      {
        path: "ap/news/:id",
        component: NewsDetailComponent,
      },
      {
        path: "ap/report",
        component: ReportComponent,
      },
      {
        path: "ap/rule",
        component: RuleComponent,
      },
      {
        path: "ap/user",
        component: UserComponent,
      },
    ],
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "ap/signin",
        component: SigninComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const RoutingComponents = [
  HomeComponent,
  ZeelyComponent,
  TuluyComponent,
  BlogComponent,
  DetailComponent,
  AboutComponent,
  HelpComponent,
  ScoringComponent,
  ServiceinfoComponent,
  PolicyComponent,
  AccessibilityComponent,
  InfosecComponent,
  WhistleComponent,
];
