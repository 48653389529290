import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Question } from 'src/app/models/scr';
import { ConstantsService } from '../constants.service';

@Injectable({
    providedIn: 'root'
})
export class PsyService {

    constructor(
        private http: HttpClient,
        private _const: ConstantsService
    ) { }

    getTest(): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("scr-test"));
    }

    setTest(id: number, ques: Array<Question>): Observable<any> {
        var req = {
            "infoId": id / 123,
            "emojiName": "Web",
            "reqQuestions": ques
        };
        return this.http.post<any>(this._const.apiUrl("scr-done"), req);
    }
}