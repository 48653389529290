import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Signin } from 'src/app/models/admin';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})
export class SigninComponent implements OnInit {
  validateForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private storage: StorageService,
    private auth: AuthService,
    private router: Router,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [false]
    });
    var rem = this.storage.GetRemember();
    if (rem != null) {
      this.validateForm.controls['userName'].setValue(rem);
      this.validateForm.controls['remember'].setValue(true);
    }
  }

  submitForm(): void {
    if (!this.validateForm.valid) {
      return;
    }
    if (this.validateForm.controls['remember'].value) {
      this.storage.SetRemember(this.validateForm.controls['userName'].value);
    } else {
      this.storage.RemoveRemember();
    }
    this.auth.loginUser(this.validateForm.controls['userName'].value, this.validateForm.controls['password'].value)
      .subscribe(
        data => {
          let signin = data as Signin;
          this.storage.SetAuthorizationData(signin);
          let redirect = this.auth.redirectUrl ? this.router.parseUrl(this.auth.redirectUrl) : '/ap';
          this.router.navigateByUrl(redirect);
        },
        error => {
          this.createMessage(error.Message);
        }
      )
  }
  createMessage(txt: string): void {
    this.message.create('error', `${txt}`);
  }
}
