<ul nz-list [nzDataSource]="data" >
    <nz-list-header style="background-color: white;">
        <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="open(0)" >
                    <span nz-icon nzType="plus-circle" style="color: #2D66B1; margin-right: 20px; font-size: 20px;" nzTheme="outline"></span></a>
              </nz-list-item-action>
            </ul>
            <span style="font-weight: 600; font-size: 18px; margin-left: 20px;">Тайлангийн жагсаалт</span>
          </li>
  
    </nz-list-header>

    <nz-table #columnTable [nzData]="data"  [nzFrontPagination]="false"  style="margin: 20px;" >
        <thead>
          <tr>
            <th nzWidth="5%" nzLeft>Дугаар</th>
            <th nzLeft>Он</th>
            <th>Нэр</th>
            <th>Төрөл</th>
            <th>Эрэмбэ</th>
            <th>Архивласан эсэх</th>
            <th nzWidth="5%" nzRight>Үйлдэл</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of columnTable.data">
            <td nzLeft>{{ data.reportId }}</td>
            <td nzLeft>{{ data.reportYear }}</td>
            <td nzLeft> <a href="{{ data.filePath }}" target="_blank">{{ data.reportName }}</a></td>

            <td nzLeft> <nz-tag [nzColor]="data.reportType ==='Finance' ?'geekblue':'orange'">{{ data.reportType ==='Finance' ? 'Санхүүгийн тайлан':'Үйл ажиллагааны тайлан' }}</nz-tag></td>
            <td nzLeft> <nz-tag [nzColor]="'blue'">{{ data.order }}</nz-tag></td>
            <td nzLeft> <nz-tag [nzColor]="data.isArchive ===true ? 'green':'volcano'">{{ data.isArchive ===true ? 'Тийм':'Үгүй' }}</nz-tag></td>

            <td nzRight>
              <a nz-popconfirm nzPopconfirmTitle="Устгахдаа итгэлтэй байна уу?" (nzOnConfirm)="deleteReport(data.reportId)"><i style="color: red; margin-right: 10px;" nz-icon nzType="delete"></i></a>
              <a (click)="open(data.reportId)" ><i style="color: orange; " nz-icon nzType="edit"></i></a>
            </td>
          </tr>
        </tbody>
    </nz-table>
    <div nz-row nzAlign="bottom" nzJustify="end">
        <div nz-col>
          <nz-pagination (nzPageIndex)='pid' [nzTotal]="rowCount" [nzSize]="'small'" (nzPageIndexChange)="pageOnChange($event)"></nz-pagination>
        </div>
    </div>
    <nz-drawer
        [nzClosable]="false"
        [nzVisible]="visible"
        nzPlacement="right"
        nzTitle="Тайлан нэмэх"
        [nzFooter]="footerTpl"
        (nzOnClose)="close()">
            <form nz-form *nzDrawerContent [formGroup]="formData">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Оноо оруулна уу!">
                     <nz-form-label nzRequired>Он</nz-form-label>
                    <nz-input-group >
                      <input formControlName="reportYear" type="number" nz-input  />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Нэр оруулна уу!">
                       <nz-form-label nzRequired>Нэр</nz-form-label>
                      <nz-input-group >
                        <input formControlName="reportName"  nz-input  />
                      </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Эрэмбэ оруулна уу!">
                       <nz-form-label nzRequired>Эрэмбэ</nz-form-label>
                      <nz-input-group >
                        <input formControlName="order" type="number"  nz-input  />
                      </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Төрөл сонгоно уу!">
                       <nz-form-label nzRequired>Төрөл</nz-form-label>
                       <nz-select [(ngModel)]="reportType" nzSize="default" formControlName="reportType" [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-control >
                        <nz-form-label nzRequired>Архивлах эсэх</nz-form-label>
                        <nz-radio-group [(ngModel)]="radioValue" [ngModelOptions]="{standalone: true}" style="width: 100%;"nzButtonStyle="solid" nzSize="small" formControlName="isArchive">
                            <label *ngFor="let option of archiveOptions" style="width: 50%; text-align: center;"nz-radio-button [nzValue]= option.value>{{option.label}}</label>
                        </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-control >
                    <nz-upload [nzAccept]="[ '.pdf' ]" [nzShowUploadList]="true" 
                    [(nzFileList)]="fileList" (nzBeforeUpload)="beforeUpload" 
                    (nzChange)="handleChange($event)" [nzLimit]="1" [nzMultiple]="false" >
                        <button nz-button >
                            <span nz-icon nzType="upload"></span>
                            Файлаа оруулна уу
                        </button>
                    </nz-upload>
                    </nz-form-control>
                </form>
              <ng-template #footerTpl>
                <div style="float: right">
                    <button nz-button class="login-form-button login-form-margin" nzSize="large" [nzType]="'primary'" (click)="submitOk()">Хадгалах</button>
                </div>
              </ng-template>
    </nz-drawer>
</ul>
