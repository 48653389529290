<!--====== TULUY START ======-->

<section id="about" class="about-area pt-115 mb-150">
  <div class="container" style="margin-top: 20px; margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1"
              >[Мэдээлэл өг]</span
            >
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #424852">[Мэдээлэл өг]</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/about-1.png" alt="service" />
        </div>
      </div>
    </div>
  </div>
  <!-- container -->
</section>

<!--====== TULUY ======-->
