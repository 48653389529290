<ul nz-list [nzDataSource]="data" >
    <nz-list-header style="background-color: white;">
        <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="showModal(false, 0)" >
                    <span nz-icon nzType="plus-circle" style="color: #2D66B1; margin-right: 20px; font-size: 20px;" nzTheme="outline"></span></a>
              </nz-list-item-action>
            </ul>
            <span style="font-weight: 600; font-size: 18px; margin-left: 20px;">Баннерын жагсаалт</span>
          </li>
  
    </nz-list-header>

    <nz-table #columnTable [nzData]="data" [nzFrontPagination]="false" style="margin: 20px;" >
        <thead>
          <tr>
            <th nzWidth="5%" nzLeft>Дугаар</th>
            <th nzLeft>Нэр</th>
            <th>Линк</th>
            <th nzWidth="5%" nzRight>Үйлдэл</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of columnTable.data">
            <td nzLeft>{{ data.bannerId }}</td>
            <td nzLeft>{{ data.name }}</td>
            <td>{{ data.deeplink }}</td>
            <td nzRight>
              <a nz-popconfirm nzPopconfirmTitle="Устгахдаа итгэлтэй байна уу?" (nzOnConfirm)="deleteBanner(data.bannerId)"><i style="color: red; margin-right: 10px;" nz-icon nzType="delete"></i></a>
              <a (click)="showModal(true,data.bannerId)" ><i style="color: orange; " nz-icon nzType="edit"></i></a>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-modal [nzVisible]="isVisible" nzTitle="{{modalTitle}}" [nzOkLoading]="isLoading" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent  style="width: 100%;">
            <form nz-form   [formGroup]="formData" (ngSubmit)="handleOk()" >
                <nz-form-item>
                  <nz-form-control nzErrorTip="Баннерын нэр оруулна уу!">
                    <nz-input-group >
                      <input formControlName="name" nz-input placeholder="Баннерын нэр оруулна уу" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Баннерын линк оруулна уу!">
                    <nz-input-group >
                      <input formControlName="deeplink" nz-input placeholder="Баннерын линк оруулна уу" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                    <nz-upload
                        nzListType="picture" (nzChange)="handleChange($event)" >
                        <img *ngIf="photoUrl" [src]="photoUrl"
                            style="width: 100%;height: 100%;" />
                        <button nz-button *ngIf="!photoUrl">
                        <span nz-icon nzType="upload"></span>
                        Зургаа оруулна уу
                        </button>
                    </nz-upload>
              </form>
        </ng-container>
      </nz-modal>
</ul>
<!-- <app-paginate [pid]="pid" [psize]="psize" [ptotal]="pTotal" [allrow]="rowCount" (result)="pageOnChange($event)"
    (size)="pageSizeChange($event)">
</app-paginate> -->