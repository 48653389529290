<ul nz-list  >
    <nz-list-header style="background-color: white;">
        <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions>
            </ul>
            <span style="font-weight: 600; font-size: 18px; margin-left: 20px;">{{isEdit ? 'Мэдээ засах': 'Мэдээ оруулах' }}</span>
        </li>

    </nz-list-header>
    <form nz-form  class="form" [formGroup]="formData">
        <div nz-row [nzGutter]="16">
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Гарчиг(mn)</nz-form-label>
                <nz-form-item>
                <nz-form-control nzErrorTip="Мэдээллээ оруулна уу!">
                <nz-input-group   >
                    <input formControlName="title" nz-input placeholder="Гарчиг" />
                </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="6"> 
                <nz-form-label nzRequired>Гарчиг(en)</nz-form-label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Мэдээллээ оруулна уу!">
                    <nz-input-group   >
                        <input formControlName="title2" nz-input placeholder="Гарчиг" />
                    </nz-input-group>
                    </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="6"> 
                <nz-form-label nzRequired>Нийтлэгчийн нэр(mn)</nz-form-label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Мэдээллээ оруулна уу!">
                    <nz-input-group   >
                        <input formControlName="name" nz-input placeholder="Нийтлэгчийн нэр" />
                    </nz-input-group>
                    </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="6"> 
                <nz-form-label nzRequired>Нийтлэгчийн нэр(en)</nz-form-label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Мэдээллээ оруулна уу!">
                    <nz-input-group   >
                        <input formControlName="name2" nz-input placeholder="Нийтлэгчийн нэр" />
                    </nz-input-group>
                    </nz-form-control>
            </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Таг(mn)</nz-form-label>
                    <nz-form-item>
                        <nz-form-control >
                            <nz-select nzMode="tags" [nzPlaceHolder]="'Таг бичээд enter дарна уу'" 
                                [(ngModel)]="selectedTagItems" [ngModelOptions]="{standalone: true}" 
                                (ngModelChange)="onSelectionTagChange($event)">
                            </nz-select>
                    </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Таг(en)</nz-form-label>
                    <nz-form-item>
                        <nz-form-control>
                            <nz-select nzMode="tags" [nzPlaceHolder]="'Таг бичээд enter дарна уу'" 
                                [(ngModel)]="selectedTag2Items"[ngModelOptions]="{standalone: true}" 
                                (ngModelChange)="onSelectionTag2Change($event)">
                            </nz-select>
                    </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="6"> 
                <nz-form-label nzRequired>Эрэмбэ</nz-form-label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Мэдээллээ оруулна уу!">
                    <nz-input-group   >
                        <input formControlName="order" type="number"  nz-input placeholder="Эрэмбэ" />
                    </nz-input-group>
                    </nz-form-control>
            </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="16"> 
            <div nz-col nzSpan="12" class="gutter-row"> 

            <nz-form-label nzRequired>Текст(mn)</nz-form-label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Текст хоосон!" class="angular-editor-textarea">
                    <angular-editor [placeholder]="'Текст мэдээлэл оруулах'" [config]="editorConfig"
                        formControlName="text">
                    </angular-editor>
                </nz-form-control>
            </nz-form-item>
            </div>
            <div nz-col nzSpan="12" class="gutter-row"> 

                <nz-form-label nzRequired>Текст(en)</nz-form-label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Текст хоосон!" class="angular-editor-textarea">
                        <angular-editor #editor [placeholder]="'Текст мэдээлэл оруулах'" [config]="editorConfig"
                            formControlName="text2">
                        </angular-editor>
                    </nz-form-control>
                </nz-form-item>
                </div>
        </div>
        <div nz-row [nzGutter]="16"> 
            <div nz-col nzSpan="6" class="gutter-row"> 

            <nz-form-label nzRequired>Нийтлэгчийн зураг</nz-form-label>
                <nz-form-item>
                    <nz-upload
                    nzListType="picture" (nzChange)="handleChange($event, 'user')" >
                    <img *ngIf="userPhotoUrl" [src]="userPhotoUrl"
                        style="width: 100%;height: 100%;" />
                    <button nz-button *ngIf="!userPhotoUrl">
                    <span nz-icon nzType="upload"></span>
                    Зургаа оруулна уу
                    </button>
                </nz-upload>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Босоо зураг</nz-form-label>
                    <nz-form-item>
                        <nz-upload
                        nzListType="picture" (nzChange)="handleChange($event, 'detail')" >
                        <img *ngIf="blogPhotoUrl" [src]="blogPhotoUrl"
                            style="width: 100%;height: 100%;" />
                        <button nz-button *ngIf="!blogPhotoUrl">
                        <span nz-icon nzType="upload"></span>
                        Зургаа оруулна уу
                        </button>
                    </nz-upload>
                    </nz-form-item>
            </div>
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Дэлгэрэнгүй зураг(en)</nz-form-label>
                    <nz-form-item>
                        <nz-upload
                        nzListType="picture" (nzChange)="handleChange($event, 'detail2')" >
                        <img *ngIf="blogPhotoUrl2" [src]="blogPhotoUrl2"
                            style="width: 100%;height: 100%;" />
                        <button nz-button *ngIf="!blogPhotoUrl2">
                        <span nz-icon nzType="upload"></span>
                        Зургаа оруулна уу
                        </button>
                    </nz-upload>
                    </nz-form-item>
            </div>
            <div nz-col nzSpan="6" class="gutter-row"> 
                <nz-form-label nzRequired>Дэлгэрэнгүй зураг</nz-form-label>
                    <nz-form-item>
                        <nz-upload
                        nzListType="picture" (nzChange)="handleChange($event, 'photo')" >
                        <img *ngIf="coverPhotoUrl" [src]="coverPhotoUrl"
                            style="width: 100%;height: 100%;" />
                        <button nz-button *ngIf="!coverPhotoUrl">
                        <span nz-icon nzType="upload"></span>
                        Зургаа оруулна уу
                        </button>
                    </nz-upload>
                    </nz-form-item>
            </div>
        </div>
        <div nz-row nzAlign="bottom" nzJustify="end">
            <div nz-col>
                <button nz-button class="login-form-button login-form-margin" nzSize="large" [nzType]="'primary'" (click)="submit()">Хадгалах</button>
            </div>
        </div>
    </form>
  </ul>