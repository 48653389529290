import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private http: HttpClient,
    private _const: ConstantsService
) { }

    getReports(reportType : string): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("reports"+"?reportType="+reportType));
    }

    getRules(ruleType : string): Observable<any> {
      return this.http.get<any>(this._const.apiUrl("rules"+"?ruleType="+ruleType));
  }

}
