import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Blog, Blogs } from 'src/app/models/blog';
import { BlogService } from 'src/app/services/blog/blog.service';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {

  blogDetailLink: string = "";
  blogDatas: Blogs | undefined;
  blogMain: Blog | undefined;

  constructor(
    private route: ActivatedRoute,
    private _const: ConstantsService,
    private _blog: BlogService
  ) { }

  ngOnInit(): void {
    this.blogDetailLink = this._const.routerUrl(this._const.blogStr);
    let id = this.route.snapshot.paramMap.get('id');
    this._blog.getPost(id)
      .subscribe(
        data1 => {
          this.blogMain = new Blog(data1);
        },
        error => { console.log(error); }
      );

    this._blog.getPosts()
      .subscribe(
        data2 => {
          this.blogDatas = new Blogs(this.shuffle(data2));
        },
        error => { console.log(error); }
      );
  }

  shuffle(array: any) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

}