import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { AdminService } from 'src/app/admin.service';
import { Banner } from 'src/app/models/models';
import { API_BASE_URL } from 'src/app/services/config';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent implements OnInit {
  pid :number = 0;
  psize: number= 10;
  
  data :Banner[] = [];
  banner : Banner | undefined;

  pTotal : number =0;
  rowCount : number =0;
  isVisible : boolean = false;
  isEdit : boolean = false;
  modalTitle : string= 'Баннер нэмэх';
  isLoading: boolean = false;
  photoUrl?: String;

  formData!: FormGroup;

  constructor(
    private admin: AdminService,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.myForm();
    this.admin.getBanners(this.pid, this.psize)
    .subscribe(
      data => {
        this.data = data.results as Banner[];
        this.pTotal = data.pageCount;
        this.rowCount = data.rowCount;
      },
      error => console.log(error + ' Error => getCustFeedbacks')
    );
  }
  showModal(isEdit : boolean, id: number): void {
    if(isEdit&& id !=0){
      this.modalTitle = 'Баннер засах'
      this.isEdit = true;
      this.admin.getBanner(id)
          .subscribe(
            data => {
              this.banner = data as Banner;
              this.formData.controls['bannerId'].setValue(this.banner.bannerId);
              this.formData.controls['name'].setValue(this.banner.name);
              this.formData.controls['deeplink'].setValue(this.banner.deeplink);
              this.photoUrl = this.banner.link;
            },
            error => console.log(error + ' Error => getOurTeam()')
          );
    }else {
      this.modalTitle = 'Баннер нэмэх'
      this.formData.controls['bannerId'].setValue(0);
      this.formData.controls['name'].setValue(null);
      this.formData.controls['deeplink'].setValue(null);
      this.photoUrl = undefined;
    }
    this.isVisible = true;
  }
  myForm() {
    this.formData = this.fb.group({
      bannerId: [0],
      name: [null, [Validators.required]],
      linkBase64: [null, [Validators.required]],
      deeplink: [null, [Validators.required]],
    });
  }
  
  handleOk(): void {
    this.isLoading = true;
    let team = new Banner(this.formData.value);
    this.admin.saveBanner(team, this.isEdit)
      .subscribe(
        data => {
          this.msg.success('Амжилттай хадгалагдлаа');
          let mem = data as Banner;
          this.ngOnInit(); 
          this.isVisible = false;
        },
        error => {
          this.msg.error(error.Message);
          console.log(error + ' Error => saveOurTeam()')
        }
      );
    this.isLoading = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.getBase64(info.file!.originFileObj!, (file: string) => {
      this.isLoading = false;
          this.formData.controls['linkBase64'].setValue(file)
      });
  }
  deleteBanner(id: any) {
    this.admin.deleteBanner(id)
      .subscribe(
        data => { this.ngOnInit(); },
        error => console.log(error + ' Error => deleteBanner')
      );
  }
}
