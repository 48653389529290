<body style="background: #0e0f0f;padding:0;margin: 0;">
    <div style="height: 150px; align-content: center; ">
    </div>

    <nz-card class="card" [nzBordered]="false" [nzCover]="coverTemplate">
        <ng-template #coverTemplate>
            <img src="assets/zeely/logo.png" alt="Logo" style="width: 15%;height: 15%;display: block;
            margin-left: auto;
            margin-right: auto; padding-top: 20px; " />
          </ng-template>
        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()" class="form">
           
            <nz-form-item>
                <nz-form-control nzErrorTip="Нэвтрэх нэр хоосон!"style="background-color: #0e0f0f;" >
                    <nz-input-group  style="background-color: #0e0f0f;border: none;" [nzPrefix]="prefixTemplateUser" nzBorderless>
                        <input class="input" type="text" nz-input formControlName="userName" placeholder="Нэвтрэх нэр" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="Нууц үг хоосон!">
                    <nz-input-group  style="background-color: #0e0f0f;border: none;" [nzPrefix]="prefixTemplateLock">
                        <input class="input" type="password" nz-input formControlName="password" placeholder="Нууц үг" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <ng-template #prefixTemplateUser><span nz-icon nzType="user" style="color: white;"></span></ng-template>
            <ng-template #prefixTemplateLock><span nz-icon nzType="lock" style="color: white;"></span></ng-template>

               
            <button nz-button class="login-form-button login-form-margin" nzType="primary" nzBlock
                [disabled]="!validateForm.valid">Нэвтрэх</button>
        </form>
    </nz-card>
</body>