<!--====== ZEELY START ======-->

<section id="about" class="about-area pt-115">
  <div class="container" style="margin-top: 20px; margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-7">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "zeely.about.title" | translate
            }}</span>
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff">
                {{ "zeely.about.desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: right"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/zeely-tit.png" alt="service" />
        </div>
      </div>
    </div>
  </div>
  <!--====== Register Screenshots ======-->
  <div class="container mt-150 wow fadeIn">
    <div class="row">
      <div class="col-lg-3" *ngFor="let image of registerImages">
        <img src="{{ image }}" alt="social" style="margin-bottom: 10px" />
      </div>
    </div>
  </div>
  <!--====== 3 ======-->
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "zeely.main.2" | translate
            }}</span>
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff">
                {{ "zeely.main.2desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/app-signup.png" alt="service" />
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="our-services-image mt-50 wow fadeInLeftBig"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/scr-test-x.png" alt="service" />
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "zeely.main.3" | translate
            }}</span>
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff">
                {{ "zeely.main.3desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "zeely.main.4" | translate
            }}</span>
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff">
                {{ "zeely.main.4desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/online-cont-x.png" alt="service" />
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="our-services-image mt-50 wow fadeInLeftBig"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="assets/zeely/zadargaa4.png" alt="service" />
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 35px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "zeely.main.5" | translate
            }}</span>
          </div>
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff">
                {{ "zeely.main.5desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pt-150">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "zeely.main.uzuulelt.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-4 mt-50">
        <div class="single-testimonial" style="height: 350px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image-plus">
                <img src="assets/zeely/alarm.png" alt="author" />
              </div>
              <div class="testimonial-text" style="padding: 25px 0 0 25px">
                <p class="text" style="color: #2d66b1; font-weight: 600">
                  {{ "zeely.main.uzuulelt.hyalbar" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text">
            <p class="text" style="font-size: 20px">
              {{ "zeely.main.uzuulelt.hyalbard" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-50">
        <div class="single-testimonial" style="height: 350px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image-plus">
                <img src="assets/zeely/lock-alt.png" alt="author" />
              </div>
              <div class="testimonial-text" style="padding: 25px 0 0 25px">
                <p class="text" style="color: #2d66b1; font-weight: 600">
                  {{ "zeely.main.uzuulelt.tech" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text">
            <p class="text" style="font-size: 20px">
              {{ "zeely.main.uzuulelt.techd" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-50">
        <div class="single-testimonial" style="height: 350px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image-plus">
                <img src="assets/zeely/paper-plane.png" alt="author" />
              </div>
              <div class="testimonial-text" style="padding: 25px 0 0 25px">
                <p class="text" style="color: #2d66b1; font-weight: 600">
                  {{ "zeely.main.uzuulelt.shuurhai" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text">
            <p class="text" style="font-size: 20px">
              {{ "zeely.main.uzuulelt.shuurhaid" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pt-150" style="margin-bottom: 100px">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "zeely.main.setgegdel.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->
    <div
      class="row testimonial-right-content mt-50 wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-4">
        <div class="single-testimonial" style="height: 210px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image">
                <img
                  src="assets/zeely/dandar_erdenetuya.png"
                  alt="author"
                  width="40"
                />
              </div>
              <div class="testimonial-text" style="padding-left: 15px">
                <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                  {{ "zeely.main.setgegdel.person.1.ner" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text" style="color: #fff; padding-top: 10px">
            {{ "zeely.main.setgegdel.person.1.comment" | translate }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="single-testimonial" style="height: 210px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image">
                <img
                  src="assets/zeely/moogii_moog.png"
                  alt="author"
                  width="40"
                />
              </div>
              <div class="testimonial-text" style="padding-left: 15px">
                <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                  {{ "zeely.main.setgegdel.person.2.ner" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text" style="color: #fff; padding-top: 10px">
            {{ "zeely.main.setgegdel.person.2.comment" | translate }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="single-testimonial" style="height: 210px">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image">
                <img src="assets/zeely/genenkhuu.png" alt="author" width="40" />
              </div>
              <div class="testimonial-text" style="padding-left: 15px">
                <p style="color: #2d66b1; font-size: 15px; font-weight: 600">
                  {{ "zeely.main.setgegdel.person.3.ner" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text" style="color: white; padding-top: 10px">
            {{ "zeely.main.setgegdel.person.3.comment" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- testimonial right content -->
  </div>
  <!-- container -->
</section>

<!--====== ZEELY ======-->
