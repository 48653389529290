<ul nz-list [nzDataSource]="data" >
    <nz-list-header style="background-color: white;">
        <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="addNews(0)" >
                    <span nz-icon nzType="plus-circle" style="color: #2D66B1; margin-right: 20px; font-size: 20px;" nzTheme="outline"></span></a>
              </nz-list-item-action>
            </ul>
            <span style="font-weight: 600; font-size: 18px; margin-left: 20px;">Мэдээний жагсаалт</span>
          </li>
  
    </nz-list-header>

    <nz-table #columnTable [nzData]="data"  [nzFrontPagination]="false"  style="margin: 20px;" >
        <thead>
          <tr>
            <th nzWidth="5%" nzLeft>Дугаар</th>
            <th nzLeft>Нийтлэгч(mn)</th>
            <th>Нийтлэгч(en)</th>
            <th>Гарчиг(mn)</th>
            <th>Гарчиг(en)</th>
            <th>Онцлосон эсэх</th>
            <th>Архивласан эсэх</th>
            <th nzWidth="5%" nzRight>Үйлдэл</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of columnTable.data">
            <td nzLeft>{{ data.blogId }}</td>
            <td nzLeft>{{ data.name }}</td>
            <td nzLeft>{{ data.name2 }}</td>
            <td nzLeft>{{ data.title }}</td>
            <td nzLeft>{{ data.title2 }}</td>
            <td nzLeft> <nz-tag [nzColor]="data.isSpecial ===true ? 'green':'volcano'">{{ data.isSpecial ===true ? 'Тийм ':'Үгүй ' }}
            </nz-tag>
              <a nz-popconfirm nzPopconfirmTitle="{{data.isSpecial ===false?'Онцлох уу':'Онцлохоо болих уу?'}}" 
              (nzOnConfirm)="specialize(data, data.isSpecial ===false ? true : false)">
              <i style="color: orange; margin-right: 10px;" nz-icon nzType="form">
              </i>
              </a></td>
            <td nzLeft> <nz-tag [nzColor]="data.isArchive ===true ? 'green':'volcano'">{{ data.isArchive ===true ? 'Тийм ':'Үгүй ' }}
             </nz-tag>
             <a nz-popconfirm nzPopconfirmTitle="{{data.isArchive ===true ? 'Болих үү?': 'Архивлах уу?'}}" (nzOnConfirm)="archive(data, data.isArchive ===true ? false : true)">
              <i style="color: orange; margin-right: 10px;" nz-icon nzType="form">
              </i>
            </a>
            </td>
            <td nzRight>
              <a nz-popconfirm nzPopconfirmTitle="Устгахдаа итгэлтэй байна уу?" (nzOnConfirm)="deleteBanner(data.blogId)"><i style="color: red; margin-right: 10px;" nz-icon nzType="delete"></i></a>
              <a routerLink="/ap/news/{{data.blogId}}" ><i style="color: orange; " nz-icon nzType="edit"></i></a>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div nz-row nzAlign="bottom" nzJustify="end">
        <div nz-col>
          <nz-pagination (nzPageIndex)='pid' [nzTotal]="rowCount" [nzSize]="'small'" (nzPageIndexChange)="pageOnChange($event)"></nz-pagination>
        </div>
    </div>
      
</ul>
