import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { AdminService } from 'src/app/admin.service';
import { ReportModel, RuleModel } from 'src/app/models/models';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.less']
})
export class RuleComponent implements OnInit {
  pid :number = 0;
  psize: number= 10;
  
  data :RuleModel[] = [];
  ruleDate : RuleModel | undefined;

  archiveOptions : Array<{label : string; value:boolean}>=[
    {label:"Тийм", value:true},
    {label:"Үгүй", value:false}
  ]

  pTotal : number =0;
  rowCount : number =0;
  isVisible : boolean = false;
  isEdit : boolean = false;
  modalTitle : string= 'Баннер нэмэх';
  isLoading: boolean = false;
  photoUrl?: String;
  radioValue : boolean | undefined;

  formData!: FormGroup;

  ruleType : string| undefined;

  fileList: NzUploadFile[] = [];

  listOfOption: Array<{ label: string; value: string }> = [
    {label:"Дүрэм, журам", value:"Rule"},
    {label:"Бодлого", value:"Policy"}
  ];

  constructor(
    private admin: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }

  ngOnInit(pid: number = this.pid): void {
    this.initData();
    this.admin.getRules(pid, this.psize)
    .subscribe(
      data => {
        this.data = data.results as RuleModel[];
        this.pTotal = data.pageCount;
        this.rowCount = data.rowCount;
        console.log(this.pTotal)
      },
      error => console.log(error + ' Error => getCustFeedbacks')
    );
  }
  initData(){
    this.formData = this.fb.group({
      ruleId: [0],
      ruleName: [null, [Validators.required]],
      ruleType: [null,[Validators.required]],
      isArchive: [this.radioValue],
      order:[null,[Validators.required]],
      fileBase64: [null],
      filePath : [""]
    });
  }
  deleteRule(id: any) {
    this.admin.deleteRule(id)
      .subscribe(
        data => { this.ngOnInit(); },
        error => console.log(error + ' Error => deleteReport')
      );
  }
  addNews(id : number ) {
    this.router.navigateByUrl('ap/news/add');
  }
  pageOnChange(newPage: number) {
    this.ngOnInit(newPage);
  }
  pageSizeChange(newSize: number) {
    this.psize = newSize;
    this.ngOnInit();
  }
  visible = false;

  open(id:number): void {
    this.visible = true;
    if(id !==0){
      this.isEdit = true;
      this.admin.getRuleById(id)
      .subscribe(
        async data => {
          this.ruleDate = data as RuleModel;
          this.formData.controls['ruleId'].setValue(this.ruleDate.ruleId);
          this.formData.controls['ruleName'].setValue(this.ruleDate.ruleName);
          this.formData.controls['ruleType'].setValue(this.ruleDate.ruleType);
          this.formData.controls['order'].setValue(this.ruleDate.order);
          this.formData.controls['isArchive'].setValue(this.ruleDate.isArchive);
          this.formData.controls['filePath'].setValue(this.ruleDate.filePath);
        },
        error => console.log(error + ' Error => getOurTeam()')
      );
    }
  }

  close(): void {
    this.visible = false;
    this.isEdit = false;

    this.formData.controls['ruleId'].setValue(0);
    this.formData.controls['ruleName'].setValue(null);
    this.formData.controls['ruleType'].setValue(null);
    this.formData.controls['order'].setValue(null);
    this.formData.controls['isArchive'].setValue(null);
    this.formData.controls['filePath'].setValue("");
    this.fileList = []
  }

  beforeUpload = (file: NzUploadFile) => {
    new Observable((observer: Observer<boolean>) => {
          var val = file.name.split('.');
          let ext = val[val.length - 1];
          const isFile = ext === 'pdf';
          if (!isFile) {
            this.msg.error(' Зөвхөн pdf файл оруулна уу');
            observer.complete();
            return;
          }
          observer.next(isFile);
          observer.complete();
      })
      return false; 
  };
  private getBase64(file: File, callback: (file: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(file);
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.getBase64(info.file!.originFileObj!, (file: string) => {
        this.formData.controls['fileBase64'].setValue(file);
      });
  }
  submitOk(){
    if (this.formData.valid) {
      this.isLoading = true;
      let data = new RuleModel(this.formData.value);
      console.log(data)
      this.admin.saveRule(data, this.isEdit)
        .subscribe(
          data => {
            this.msg.success('Амжилттай хадгалагдлаа');
            this.ngOnInit();
            this.close();
          },
          error => {
            this.msg.error(error.Message);
            console.log(error.StatusCode + ' Error => saveRule()')
          }
        );
      this.isLoading = false;
    } else {
      Object.values(this.formData.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

}
