import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AdminService } from 'src/app/admin.service';
import { News } from 'src/app/models/models';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less']
})
export class NewsComponent implements OnInit {
  pid :number = 0;
  psize: number= 10;
  
  data :News[] = [];
  banner : News | undefined;

  pTotal : number =0;
  rowCount : number =0;
  isVisible : boolean = false;
  isEdit : boolean = false;
  modalTitle : string= 'Баннер нэмэх';
  isLoading: boolean = false;
  photoUrl?: String;

  formData!: FormGroup;

  constructor(
    private admin: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }

  ngOnInit(pid: number = this.pid): void {
    this.admin.getNews(pid, this.psize)
    .subscribe(
      data => {
        this.data = data.results as News[];
        this.pTotal = data.pageCount;
        this.rowCount = data.rowCount;
        console.log(this.pTotal)
      },
      error => console.log(error + ' Error => getCustFeedbacks')
    );
  }
 
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  deleteBanner(id: any) {
    this.admin.deleteBlog(id)
      .subscribe(
        data => { this.ngOnInit(); },
        error => console.log(error + ' Error => deleteBanner')
      );
  }
  archive(news: News, isArchive: boolean) {
    news.isArchive = isArchive;
    this.admin.saveNews(news, true)
      .subscribe(
        data => { this.ngOnInit(); },
        error => console.log(error + ' Error => deleteBanner')
      );
  }
  specialize(news: News, isSpecial:boolean) {
    news.isSpecial = isSpecial;
    this.admin.saveNews(news, true)
      .subscribe(
        data => { this.ngOnInit(); },
        error => console.log(error + ' Error => deleteBanner')
      );
  }
  addNews(id : number ) {
    this.router.navigateByUrl('ap/news/add');
  }
  pageOnChange(newPage: number) {
    this.ngOnInit(newPage);
  }
  pageSizeChange(newSize: number) {
    this.psize = newSize;
    this.ngOnInit();
  }
}
