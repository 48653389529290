export class Tag {
    public name: String;

    constructor(tagStr: any) {
        this.name = tagStr.name;
    }
}

export class Blog {
    public blogId: String;
    public name: String;
    public title: String;
    public date: Date;
    public text: String;
    public photo: String;
    public coverPhoto: String;
    public tags: Array<Tag>;
    public orderNo: Number;
    public user: String;
    public tagStr: String;
    public isSpecial : boolean;

    constructor(blogStr: any) {
        this.blogId = blogStr.blogId;
        this.name = blogStr.name;
        this.title = blogStr.title;
        this.date = blogStr.date;
        this.text = blogStr.text;
        this.photo = blogStr.photo;
        this.coverPhoto = blogStr.coverPhoto;
        this.tags = blogStr.tags;
        this.orderNo = blogStr.orderNo;
        this.user = blogStr.user;
        this.tagStr = blogStr.tagStr;
        this.isSpecial = blogStr.isSpecial;
    }
}

export class Blogs {
    public BlogList: Array<Blog> = [];
    constructor(arry: any) {
        for (let i = 0; i < arry.length; i++) {
            let blog = new Blog(arry[i]);
            this.BlogList.push(blog);
        }
    }
}
export class Tags {
    public TagList: Array<Tag> = [];
    constructor(array: any) {
        for (let i = 0; i < array.length; i++) {
            let tag = new Tag(array[i]);
            this.TagList.push(tag);
        }
    }
}

export class blog {
    public static blogDatas = [
        {
            id: "1",
            name: "Admin М",
            title: "Буруугаа хүлээж сурах нь",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Хүн бүр	' +
                '        мэдрэмж, оюун бодолдоо өөрийгөө	' +
                '        өндрөөр үнэлэх нууцхан хүсэл тээж байдаг. Үүнийг	' +
                '        буруутгах аргагүй тархи ч энэ тал дээр хүний талд ажиллаж аль болох алдааг зөвтгөх	' +
                '        оролдлогоруу чиглүүлдэг. Өөрийн бурууг ухамсарлах, алдаа гаргасанаа хүлээн зөвшөөрөх нь танд	' +
                '        баярлууштай санагддаггүй нь лавтай, хүн бүрт адил тухгүй таагүй мэдрэмж төрүүлж байдаг.	' +
                '    </div>	' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Өөрийгөө	' +
                '        өмөөрөх нь дан ганц гаргасан	' +
                '        алдаагаа үгүйсгэж буй хэрэг биш, нөгөөтэйгүүр үзэл	' +
                '        бодол, амьдралыг харах өнцөг, хандлага, итгэл үнэмшлээ зөвтгөн хамгаалах оролдлого байж	' +
                '        болно. Алдаа гаргасан ч тэрнийгээ хүлээн зөвшөөрөхгүй байх нь түр зуур л таны сэтгэлийг	' +
                '        засах боловч алдаагаа, буруугаа нуун дарагдуулж зугтах нь түүнээс хэд дахин сэтгэлийн	' +
                '        шаналалын хуримтлал үүсгэдэг. Иймээс хэрэв та нэгний өмнө болон өөрийн өмнө буруутай бол	' +
                '        түүнийгээ хүлээн зөвшөөрөх зоригийг өөрөөсөө эрж ол.</div>	' +
                '    <div>	' +
                '        <ul>	' +
                '            <li style="text-align: justify;">- Юун түрүүнд өөртөө уурлах уураа зохицуулах хэрэгтэй,	' +
                '                уурыг зохицууж болно уур бол	' +
                '                өөрийгөө хүчээр зөвтгөх зорилгын өнгөлөн далдлалт бөгөөд асуудлаас зугтах хамгийн	' +
                '                хялбар алхам, бас таныг хамгийн буруу тийш хөтлөх зүйл.</li>	' +
                '            <li style="text-align: justify;">- Нөгөөтэйгүүр та буруугаа хүлээх дургүй байгаагаа	' +
                '                анзаарсан бол шалтгаан нь юундаа	' +
                '                байгааг бодож үзээрэй. Дийлэнхдээ хүмүүс өөртөө итгэх итгэл ба өөрийн үнэлэмжээ	' +
                '                алдах айдсаас болж буруугаа хүлээхээс зугтах хандлагатай байдаг. Иймээс та юун	' +
                '                түрүүнд өөрийгөө буруутгаж шүүмжлэлгүйгээр алдаа гаргаснаа өөртөө хүлээн зөвшөөрч,	' +
                '                дараагийн удаа алдаа гаргахгүй байх тал дээр илүү анхаарах хэрэгтэй.</li>	' +
                '            <li style="text-align: justify;">- Алдаа бол залруулж болох зүйл харин та өөрчлөгдсөн	' +
                '                гэсэн үг биш. Иймээс өөрийн	' +
                '                үнэлэмжээ бүү гэмтээ. Эцэст нь уучлалт гуйхад хэзээд бурууддаггүйг санаарай, та	' +
                '                гаргасан алдаагаа хүлээн зөвшөөрч уучлалт гуйснаар өөрийн хариуцлагатай байдлаа	' +
                '                баталж байгаа хэрэг. Үүний тулд цахим шуудангаар, утсаар ярьж хэлэх эсвэл нүүр	' +
                '                тулсан ярилцлага хийсэн ч болно. Гол нь таны алдааны үр дүнд хэн нэгэн хохирсон,	' +
                '               гомдсон бол та уучлалт гуйснаар асуудлын шийдэл эхлэх болно.</li>	' +
                '        </ul>	' +
                '    </div>	',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "2",
            name: "Admin T",
            title: "Үрэлгэн зангаа хэрхэн засах вэ",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мөнгөтэй	' +
                '        холбоотой үйлдэл, шийдвэрүүдэд хүн мөнгөний талаар ямар ойлголттой, итгэл үнэмшилтэй байдаг	' +
                '        нь чухал нөлөөтэй болохыг “Мөнгөний сэтгэл зүй/Psychology of Money” судлалын онол дэвшүүлсэн	' +
                '        байдаг. Хүн бүр мөнгийн зохицуулах байдал харилцан адилгүй ба тус бүр өөрийн шалтгаантай.	' +
                '        Жишээлбэл “Хадгалагчид” өөрт хэрэгцээтэй зүйлсийг үл тоогоод банкны хадгаламжин дахь мөнгөө	' +
                '        бодож сэтгэл ханах нь чухал байдаг. Хадгаламжийн дүн нэмэгдэх тусам тэдний сэтгэл ханамж	' +
                '        ихэсч, урт хугацаанд хуримтлуулсан хадгаламжаараа ямар нэгэн зүйл худалдан авахаас эмээдэг.	' +
                '        Харин эдгээр хүмүүсийн эсрэг төрлийн “Хэтрүүлэн үрэгчид” нь амьдралыг зугаа цэнгэл болгож	' +
                '        хардаг, ирээдүйд яах талаараа боддоггүй бөгөөд одоо авахыг хүссэн зүйлээ авахыг маш их	' +
                '        хүсдэг, авдаг хүмүүс.</div>	' +
                '    <div>Хэрэв хэт үрэгч төрлийн хүн бол танд үрэлгэн байдалтайгаа тэмцэх дараах алхмуудыг санал	' +
                '        болгож байна:</div>	' +
                '    <div>	' +
                '        <ul>	' +
                '            <li>1. Зээлийн картнаас болгоомжил. Төсөвтөө тохируулан зардлаа тооцох.</li>	' +
                '            <li>2. Хадгалах зорилгынхоо талаар бусадтай ярилц. Гэр бүлийнхэндээ өөрийгөө	' +
                '                хадгаламжтай болж түүгээрээ юу хийхээр төлөвлөж байгаа талаар гээд нээлттэй ярилц,	' +
                '                тэд таныг хэт үрэхээс сэргийлнэ. </li>	' +
                '            <li>3. Хэрэглэсэн хэмжээгээрээ л төл. Жишээ нь найзуудаараа гадуур гарлаа гэхэд урдаа	' +
                '                байгаа бүхнийг төлөх гэж яарах хэрэггүй. Тэгж яарах тусах мөнгө ямар хурдан	' +
                '                дууссаныг та гайхсаар байх болно. </li>	' +
                '            <li>4. Өөрийгөө урамшуул. Хадгаламжаа зорьсон хэмжээндээ хүргэсэн бол багахан хувийг	' +
                '                өөртөө зарцуулаарай. Таны тэвчээрийн шагнал болж, урам зориг бялхах болно. </li>	' +
                '            <li>5. Өөрөөсөө асуухаа боль. Ямар нэгэн зүйлийг худалдан авах бүртээ үнэхээр хэрэгтэй	' +
                '                эсэхийг зөвхөн өөрөөсөө асуугаад байлгүй эргэлзээтэй байгаа зүйлийн талаар бусдаас	' +
                '                асуу. Хэрэгтэй болон хүсэж байгаа 2-н ялгааг гаргахад тань туслана. </li>	' +
                '            <li>6. Хичнээн хэцүү байсан ч ирээдүйн талаар бод. Тэтгэвэрт гарахад тань хэдэн төгрөг	' +
                '                хэрэгтэй талаар, хүүхдийнхээ боловсролд зарцуулах мөнгөө хэрхэн олох талаар сайн	' +
                '                бодож тунгаах нь таныг мөнгөө замбараагүй үрэх зуршлаа багасгахад туслана. </li>	' +
                '        </ul>	' +
                '    </div>	',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "3",
            name: "Admin E",
            title: "Сэтгэл хөдлөлийн тухай мэдэх хэрэгтэй зүйлс",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сэтгэл			' +
                '        хөдлөлийн үндсэн 8 төрөл байдаг гэж үздэг бөгөөд эдгээр нь баяр баясал, уйтгар гуниг, айдас,	' +
                '        жигшил зэвүүцэл, цочирдол, хүлээлт, уур уцаар ба итгэл найдвар юм. Эдгээр үндсэн сэтгэл        ' +
                '        хөдлөл хоорондоо холилдож хэдэн арван төрлийн өөр сэтгэл хөдлөл, мэдрэмжүүдийг үүсгэж          ' +
                '        байдаг, яг л өнгө шиг.</div>                                                                   ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Хүрээлэн буй     ' +
                '        юмс үзэгдэл, үйл явдал, холбоо харилцаа нь бидний сэтгэл хөдлөл үүсэн бий болох шалтгаан       ' +
                '        болдог. Өөрөөр хэлбэл дээрх үйл явдлуудыг тархи хүлээж авч л байгаа бол сэтгэл хөдлөл үүсэх    ' +
                '        нь зайлшгүй юм. Иймээс өөртөө үүсэж байгаа сэтгэл хөдлөл, түүний салшгүй хэсэг болох           ' +
                '        мэдрэмжийг бие махбодод үүсдэг жирийн л нэг үйл явц гэдгийг нь хүлээн зөвшөөрч, сэтгэл         ' +
                '        хөдлөлөө өөрөөсөө нуух, эсвэл тийм зүйл мэдэрснийхээ төлөө өөрийгөө буруутгахгүй байх нь       ' +
                '        бидний хамгийн эхэнд хийж чадах эрүүл үйлдэл байх болно.</div>                                 ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Бидэнд           ' +
                '        тохиолдож байгаа түм буман үйл явдлыг хүн бүр өөрийнхөө тухайн үеийн нөхцөл байдалтай          ' +
                '        уялдуулан хүлээж авч, өөр өөр сэтгэл хөдлөл мэдэрдэг. Цас, мөстэй холбоотой спортоор           ' +
                '        хичээллэдэг хүмүүс цас ормогц баярлаж догдлох бол, эсрэгээрээ хүйтэнд өвчин нь сэдэрдэг        ' +
                '        хүмүүс цас ормогц уйтгар гунигтай, бүр ууртай ч болж мэднэ. Тиймээс аливаа нөхцөл байдалд      ' +
                '        бүгд яг ижилхэн хариу үзүүлэхгүйг ойлгоод, өөрийгөө бусдаас өөр сэтгэл хөдлөл гаргаж байгааг   ' +
                '        өршөөж уучил.</div>                                                                            ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сэтгэл хөдлөл,   ' +
                '        мэдрэмж угаас төрөх ёстой зүйл гэдгийг ойлгож хүлээж авч чадсан бол хэзээ, хаана, хэнтэй       ' +
                '        байхад ямар сэтгэл хөдлөл, мэдрэмж төрж байна вэ гэдэг дээр төвлөрч, өөрийгөө анзаарч эхлэх    ' +
                '        нь хамгийн чухал. Энэ нь сэтгэл хөдлөлөө удирдах чадварын эхний алхам байх болно.</div>        ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "4",
            name: "Admin M",
            title: "Гэр бүлд үүссэн мөнгөтэй холбоотой зөрчилийг шийдвэрлэх",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Гэр бүлийн		' +
                '        гишүүн бүр хэвийн, хангалуун амьдарч, тэдгээрийн наад захын хэрэгцээ хангагдсан байхад         ' +
                '        санхүүгийн хүчин зүйл томоохон нөлөөтэй. Гэсэн хэдий ч гэр бүлд тулгарах 3 түгээмэл            ' +
                '        тохиолдол жишээг шийдвэрлэх аргатай нь хуваалцъя.</div>                                        ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>1. Мөнгөний хандлага ялгаатай байх. Магадгүй та орлогоосоо тодорхой хувийг хадгалдаг   ' +
                '                харин гэр бүлийн тань хүн олсноо шууд үрдэг төрлийн хүмүүс байвал мэдээж энэ том       ' +
                '                асуудал үүсгэнэ. Энэ тохиолдолд сар бүрийн зардлаа нарийн тодорхойлж түүнээсээ         ' +
                '                хэтрүүлэхгүй байх тал дээр тохиролцоонд хүрээрэй. Харин төлөвлөснөөс илүү зардал       ' +
                '                гаргавал дараа сарынхаа төсвийг улам бүр багасгах гэх мэт сонирхолтой бөгөөд үр        ' +
                '                дүнтэй арга хэрэглэж болох юм. </li>                                                   ' +
                '            <li>2. Бодит байдлаас ахадсан өр, зээл. Хэрэв танай гэр бүлд өр, зээлтэй холбоотой         ' +
                '                асуудал үүссэн бол аль болох зардлаа танах тал дээр анхааран ажиллаарай. Мөн орлогын   ' +
                '                эх үүсвэрээ зээлийн бус аргаар хэрхэн нэмэгдүүлэх боломж байгаа талаар ярилцаж,        ' +
                '                шийдэл олоорой. </li>                                                                  ' +
                '            <li>3. Эхнэр/нөхөр-ийн гэр бүлд мөнгө зээлэх. Хэдий ганц нэг тохиолдолд хань ижил,         ' +
                '                хамтран амьдрагчийнхаа гэр бүлд туслаж болох ч энэ байдал байнга давтагддаг бол        ' +
                '                асуудал үүсгэх нь элбэг. Тиймээс танай гэр бүлийн санхүүгийн нөхцөл эхэнд байх         ' +
                '                ёстой, ингэж чадахгүй бол хосын харилцаа, гэр бүлийн амьдралд асуудал үүсч болох       ' +
                '                учир энэ тал дээр тохиролцоонд хүрээрэй. </li>                                         ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "5",
            name: "Admin T",
            title: "Мөнгөтэй холбоотой үүсэх сэтгэл хөдлөлийн тухай",
            date: "2020.04.25",
            text: '<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Та маш их			' +
                '        хэмжээний мөнгөтэй болвол хамгийн түрүүнд юу мэдрэх вэ? Та баярлах уу? Та айх уу? Та санаа     ' +
                '        зовох уу? Та эсвэл .......</div>                                                               ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мөнгө болон      ' +
                '        түүнтэй холбоотой асуудлыг үүсгэж байгаа зүйл нь мөнгө өөрөө байдаггүй ажээ. Харин мөнгөний    ' +
                '        талаарх хүмүүсийн бодол, түүний талаар үүсч буй сэтгэл хөдлөл хамгийн чухал юм.</div>          ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>• Хэрэв хэн нэгэн байгаа мөнгөө хэзээ ч хангалттай гэж үздэггүй үргэлж мөнгөө их       ' +
                '                байлгахыг хүсдэг бол хэзээ ч тайван, хангалуун байж чаддагүй. </li>                    ' +
                '            <li>• Эсвэл мөнгөтэй байхыг маш буруу, ёс зүйгүй зүйл багахан мөнгөөр ч гэсэн хүн          ' +
                '                амьдарч чаддаг байх ёстой гэдэг бодол, хандлагатай бол их мөнгөтэй хүмүүсийг үзэн      ' +
                '                ядах нь ч бий. </li>                                                                   ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Харамсалтай нь   ' +
                '        мөнгөний талаар сөрөг сэтгэл хөдлөлтэй байх нь эргээд хувь хүний санхүүд асуудал дагуулдаг.    ' +
                '        Тиймээс ч эрдэмтэд мөнгийг удирдаж чадах, зохицуулах чадвар, боловсролыг хүүхэд байхаас нь     ' +
                '        бий болгохыг зөвлөдөг. Мөнгийг удирдах чадвар гэдэг нь их орлоготой, мөнгөтэй болох асуудал    ' +
                '        биш бөгөөд харин мөнгөнд захирагдахгүйгээр түүнийг хянах юм. Энэ нь тогтмол орлоготой байх,    ' +
                '        орлогод тохирсон төсөв бий болгох, шаардлагатай тохиолдолд хэрэглэх хадгаламжтай байх зэрэг    ' +
                '        энгийн дадлуудаас эхлэнэ. </div>                                                               ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мөн үнэн         ' +
                '        хэрэгтээ мөнгөнд хандах хандлага тань та өөртөө, өөрийнхөө амьдралд хандах хандлагаас          ' +
                '        үүдэлтэй гэдгийг мэдэхэд илүүдэхгүй. Хэрэв та өөртөө итгэдэг, амьдралаа удирдан зохицууулах    ' +
                '        чадвартай гэдэгтээ итгэдэг бол танд мөнгөтэй холбоотой сөрөг сэтгэл, хандлага үүсэхгүй юм.     ' +
                '    </div>                                                                                             ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "6",
            name: "Admin E",
            title: "Санхүүгийн асуудлаа гэр бүлтэйгээ хэрхэн ярилцах вэ?",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Гэр бүлүүдэд		' +
                '        санхүүгийн асуудал тулгарч байгаа бол тэд өрхийнхөө санхүүгийн нөхцөл байдал ямар байгаа       ' +
                '        талаар тодорхой нэгдсэн ойлголтгүй явж байгаа гэж хэлж болох юм. Энэ асуудлаа хоорондоо        ' +
                '        ярилцаж, нэг ойлголттой байж, цааш яахаа ярилцахгүй бол одоо болоод байгаа мэт санагдаж        ' +
                '        байгаа ч хэдхэн жилийн дараа гэхэд энгийн хэрэглээгээ ч хангах боломжгүй нөхцөл байдалд орж    ' +
                '        мэдэх юм.</div>                                                                                ' +
                '    <div style="text-align: justify;">Гэр бүлтэйгээ санхүүгийнхээ талаар ярилцахдаа дараах хэдэн       ' +
                '        зүйлийг санаж байхад илүүдэхгүй:</div>                                                         ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>- Санхүүгийн нөхцөл байдал ямар байгаа талаар илэн далангүй, нуулгүйгээр хэл.          ' +
                '                Асуудлаа хамт шийдэхийн тулд л ярилцаж байгаагаа санаарай</li>                         ' +
                '            <li>- Үүсэж байгаа асуудлаас болж гэр бүлийн гишүүн бүрт өөрсдийн гэсэн үзэл бодол,        ' +
                '                санал бий болж, ингэснээр тодорхой хэмжээний зөрчил үүсэх магадлалтайг санаарай,       ' +
                '                тиймээс зөрчил, ундуйцалд бэлэн байх хэрэгтэй</li>                                     ' +
                '            <li>- Ялангуяа насанд хүрсэн хүүхдүүдтэйгээ хамт байдаг бол тэдний үзэл бодол, асуудлыг    ' +
                '                шийдэх арга ч мөн өөр байх болно. Тиймээс хэн хэнээсээ гарч буй саналуудыг, хариу      ' +
                '                үйлдлүүдийг хүндэтгэн авч үзэх нь зүйтэй</li>                                          ' +
                '            <li>- Санхүүтэй холбоотой яриа хийх гэж байгаа бол тодорхой цаг гаргахыг хичээгээрэй.      ' +
                '                Зүгээр нэг буйдан дээр сууж байхдаа, эсвэл хоолны ширээний ард ярилцах нь яриаг        ' +
                '                чухал гэсэн сэтгэгдэл төрүүлэхгүй</li>                                                 ' +
                '            <li>- Харин тодорхой цаг гаргаад ярилцахдаа үүсээд байгаа нөхцөл байдалд гишүүн бүр ямар   ' +
                '                бодол сэтгэгдэл, хандлагатай байгааг, түүнийг хэрхэн шийдэх ямар хувилбар байгаа       ' +
                '                талаараа ярилцаарай. Нэгнийгээ яаруулалгүй, хүн бүрийн үгийг сонсох нь чухал</li>      ' +
                '            <li>- “Та нар ингэсэн, та нар тэгдэг” гэх маягаар бусдыгаа буруутгаж дайралгүй аль болох   ' +
                '                “цаашид ийм байвал зүгээр” гэх маягаар ирээдүйн шийдэлд тулгуурласан байдлаар          ' +
                '                саналаа илэрхийлээрэй</li>                                                             ' +
                '            <li>- Санал солилцохдоо саналуудаа нэгтгэж, гэр бүлийнхээ төлөө өөрийн зарим хүсэл         ' +
                '                бодлыг хойш тавих хэрэгтэй болж болоход бэлэн байгаарай</li>                           ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "7",
            name: "Admin T",
            title: "Хэрхэн гаргасан шийдвэртээ тууштай байх вэ",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Хувийн			' +
                '        хөгжлийн ном, нийтлэл, мэдээнүүд бидний хүсэл болон тууштай бус зан чанарууд дээр              ' +
                '        тулгуурласан байдаг гэдгийг та анзаардаг уу. Өөрөөр хэлбэл бидний хүсэл хязгааргүй боловч      ' +
                '        түүнийхээ төлөө бид тэвчээртэйгээр хөдөлмөрлөж чаддаггүй. Тийм ч учраас хүн төрөлхтөн ид       ' +
                '        шидэд хамгийн их дуртай байдаг. Гэхдээ тууштай байдал, тэр дундаа өөрийн шийдвэртээ тууштай    ' +
                '        байх нь бидний зорилгодоо хүрэх хамгийн гол түлхүүр болдог. Тэгвэл хэрхэн шийдвэр гаргаж,      ' +
                '        хэрхэн тууштай байх вэ?</div>                                                                  ' +
                '    <div style="text-align: justify;">Шийдвэр гаргалтыг “дүгнэлтэнд тулгуурласан” эсвэл “төсөөлөлд     ' +
                '        тулгуурласан” гэсэн хоёр төрөлд хувааж болох юм. Нөхцөлд тулгуурлан шийдвэр гаргадаг хүмүүс    ' +
                '        асуудлыг олон талаас нь дүгнэн шинжилж өөрт тохирох зөв хувилбарыг сонгодог бол төсөөлөлд      ' +
                '        тулгуурладаг хүмүүс хамгийн хүсэмжит хувилбарыг сонгодог. Энэ хоёр шийдвэр гаргалтын хамгийн   ' +
                '        том ялгаатай шинж нь гаргасан шийдвэртээ тууштай байх. Төсөөлөлд буюу хүсэмжит байдлаар        ' +
                '        шийдвэр гаргасан хүмүүсийн хувьд</div>                                                         ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>1. Шийдвэртээ итгэлгүй байх</li>                                                       ' +
                '            <li>2. Эрсдэлд бэлэн биш байх</li>                                                         ' +
                '            <li>3. Сэтгэл зүйгээ бэлдээгүй байх</li>                                                   ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ' +
                '    <div style="text-align: justify;">гэсэн шалтгаануудын улмаас шийдвэрээсээ буцдаг байна. Мэдээж     ' +
                '        хэрэг оновчтой шийдвэр гаргах нь хэцүү. Гэхдээ гаргасан шийдвэртээ үнэнч байснаар л бид үр     ' +
                '        дүн гаргаж чадна. Таны шийдвэр зөв эсвэл буруу байх нь сонголт хийхэд бус харин сонголтоо      ' +
                '        даган мөрдсөн эсэхээс л хамаардаг гэсэн үг.</div>                                              ' +
                '    <div style="text-align: justify;">Сэтгэл зүйн судалгаагаар өөрийн шийдвэртээ үнэнч байдаг хүмүүс   ' +
                '        нь хэд хэдэн нийтлэг шинжтэй байдаг нь анзаарагдсан. Тэдгээр нь:</div>                         ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>- Тэдэнд хийж болох болон хийж болохгүй гэсэн өөрийн хэмжигдэхүүн байдаг</li>          ' +
                '            <li>- Тэд бусдын сөрөг нөлөөнд бага автдаг</li>                                            ' +
                '            <li>- Тэд ямар ч сэтгэл хөдлөл богино хугацаанд үргэлжилдэг гэдгийг мэддэг</li>            ' +
                '            <li>- Тэд зорилгодоо хүрэхэд туслах мэдлэгийг өөртөө хуримтлуулдаг</li>                    ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        },
        {
            id: "8",
            name: "Admin M",
            title: "Бусдад амлалт өгөх тухай",
            date: "2020.04.25",
            text: '	<div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Амлалтандаа		' +
                '        хүрдэг хүнийг бид ухамсаргүйгээр (unconciously) өндрөөр үнэлдэг. Учир нь хэн нэгэн             ' +
                '        амлалтандаа хүрэхгүй байх нь биднийг түгшүүртэй болгодог биелэгдсэн амлалтууд бидний бусдад    ' +
                '        итгэх итгэлийг нэмэгдүүлж байдаг.</div>                                                        ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Хэрэв та         ' +
                '        хүүхэд байхаасаа амлалтандаа хүрдэггүй хүмүүс дунд байсан бол бусдад итгэж хүлээх ямар         ' +
                '        сэтгэл өвтгөм гэдгийг мэддэг байх. Гэхдээ нөгөө талаас нь харвал өөрийн хийж чадахгүй          ' +
                '        зүйлийг амлах, амлалтаа цуцлах зан байдал нь нэг төрлийн сэтгэл зүйн бэрхшээл. Жишээ нь        ' +
                '        байнга амлалт өгдөг хүмүүс бусдад таалагдах хүсэл, өөртөө итгэлгүй байдал, сэтгэл хөдлөлөө     ' +
                '        хянаж чадахгүй байх зэрэг сэтгэл зүйн хэрэгцээг дотроо нууж байдаг. Тиймээс нийгэм             ' +
                '        амлалтандаа хүрдэггүй хүнийг төлөвшөөгүй нэгэн гэж хүлээн авдаг байна.</div>                   ' +
                '    <div style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Хамгийн          ' +
                '        харамсалтай нь бүхий л биелэгдээгүй амлалтуудын дараа хэн нэгэн шархалж үлддэг. Энэ алдааг     ' +
                '        засах боломжгүй бөгөөд уучлалт гуйснаар бид гэм буруугаа цайруулж чаддаггүй. Тиймээс бидний    ' +
                '        хийх ёстой зүйл бол амлалт өгөх бүртээ энэ амлалтандаа би хүрч чадах билүү гэж бодох юм.       ' +
                '        Түүнчлэн танд хэрэг болох хэдэн зөвлөмжийг хүргэе.</div>                                       ' +
                '    <div>                                                                                              ' +
                '        <ul>                                                                                           ' +
                '            <li>1. Бусдад сайн хүн болох гэж оролдохоо болих. Заримдаа бид нийгэмд таалагдахын тулд    ' +
                '                өөрийн хэрээс хэтэрсэн амлалтууд өгдөг.</li>                                           ' +
                '            <li>2. Өөртөө хүндэтгэлтэй хандах. Амлалтанаа биелүүлнэ гэдэг нэг талаар бусдыг нөгөө      ' +
                '                талаар өөрийгөө хүндэлдэг хүний шинж байдаг.</li>                                      ' +
                '            <li>3. Өгч буй амлалт бүр зорилго гэдгийг санах. Танд зорилгодоо хүрэхэд биелэгдэх         ' +
                '                хугацаа, хичээл зүтгэл, цаг хугацаа хэрэгтэй.</li>                                     ' +
                '        </ul>                                                                                          ' +
                '    </div>                                                                                             ',
            photo: "assets/zeely/blog-bg.png",
            coverPhoto: "assets/zeely/blog-big-photo.png",
            tags: [{ name: "зөвлөгөө" }, { name: "мэдээлэл" }]
        }
    ];
}