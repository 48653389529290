import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']

})


export class AboutComponent implements OnInit {
  page = 1;

  registerImages = [
    {
      image: "assets/images/Мандахбаяр.webp",
      position: "company.headerChief",
      name: "company.mandahbayr"
    }, {
      image: "assets/images/Хосгариг.webp",
      position: "company.headerMember2",
      name: "company.hosgarig"
    },
    {
      image: "assets/images/Түвшинтөр.webp",
      position: "company.headerMember1",
      name: "company.tuwshintor"
    }, {
      image: "assets/images/Эрдэнэбаатар.webp",
      position: "company.headerMember1",
      name: "company.erdenebaatar"
    },
    {
      image: "assets/images/tumbnail-1.jpg",
      position: "company.headerMember1",
      name: "company.onchinsuren"
    }];

  commitees = [
    {
      name: "company.headersCommitees1",
      head: "company.onchinsuren",
      headImage: "assets/images/tumbnail-1.jpg",
      members: [{
        member: "company.tuwshintor",
        image: "assets/images/Түвшинтөр.webp",
      }, {
        member: "company.erdenebaatar",
        image: "assets/images/Эрдэнэбаатар.webp",
      },],
      desc: "company.commitee1"
    },
    {
      name: "company.headersCommitees2",
      head: "company.hosgarig",
      headImage: "assets/images/Хосгариг.webp",
      members: [{
        member: "company.onchinsuren",
        image: "assets/images/tumbnail-1.jpg",
      }, {
        member: "company.erdenebaatar",
        image: "assets/images/Эрдэнэбаатар.webp",
      },],
      desc: "company.commitee2"
    },
    {
      name: "company.headersCommitees3",
      head: "company.erdenebaatar",
      headImage: "assets/images/Эрдэнэбаатар.webp",
      members: [{
        member: "company.onchinsuren",
        image: "assets/images/tumbnail-1.jpg",
      }, {
        member: "company.tuwshintor",
        image: "assets/images/Түвшинтөр.webp",
      },],
      desc: "company.commitee3"
    },
  ]


  ngOnInit(): void {
  }

  changePage(number: any) {
    this.page = number;
  }

}
