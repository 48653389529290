import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Blogs } from 'src/app/models/blog';
import { BlogService } from 'src/app/services/blog/blog.service';
import { ConstantsService } from 'src/app/services/constants.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  @ViewChild('youTubePlayer') youTubePlayer!: ElementRef<HTMLDivElement>;

  videoHeight: number | undefined;
  videoWidth: number | undefined;

  blogDetailLink: string = "";
  blogDatas: Blogs | undefined;

  playerConfig = {
    controls: 0,
    loop: 1,
    playlist: 'MZgpr_yKDwU',
    rel: 0,
    autoplay: 1
  };

  constructor(
    private _const: ConstantsService,
    private _blog: BlogService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    this.blogDetailLink = this._const.routerUrl(this._const.blogStr);

    this._blog.getPosts()
      .subscribe(
        data => {
          this.blogDatas = new Blogs(this.shuffle(data));
        },
        error => { console.log(error); }
      );
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize(): void {
    this.videoWidth = this.youTubePlayer.nativeElement.clientWidth;
    this.videoHeight = this.videoWidth * 0.562;
    this.changeDetectorRef.detectChanges();
  }

  shuffle(array: any) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

}