import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './admin.component';
import { AppNzModule } from '../app.nz.module';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthComponent } from './auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewsComponent } from './news/news.component';
import { BannerComponent } from './banner/banner.component';
import { ReportComponent } from './report/report.component';
import { RuleComponent } from './rule/rule.component';
import { UserComponent } from './user/user.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminInterceptorService } from './admin.interceptor.service';
@NgModule({
  declarations: [
    AdminComponent,
    SigninComponent,
    AuthComponent,
    DashboardComponent,
    NewsComponent,
    BannerComponent,
    ReportComponent,
    RuleComponent,
    UserComponent,
    NewsDetailComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    AppNzModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AdminModule { }