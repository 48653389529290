<section id="blog" class="blog-area pt-180">
  <div class="container about-area pb-80">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "infosec.main.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->

    <div
      class="row testimonial-right-content wow fadeIn mt-40"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="container about-area pb-80">
        <div
          class="row testimonial-right-content wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.6s"
        >
          <div class="col-lg-12 mb-50 business">
            <div class="single-testimonial">
              <div
                class="testimonial-text mb-20"
                style="
                  color: #fff;
                  text-align: center;
                  line-height: 30px;
                  padding: 0 50px;
                  font-weight: bold;
                  text-align: left;
                "
              >
                {{ "infosec.main.1.title" | translate }}
              </div>
              <div
                class="testimonial-text mb-20"
                style="
                  color: #fff;
                  text-align: center;
                  line-height: 30px;
                  padding: 0 50px;
                  font-weight: bold;
                  text-align: left;
                "
              >
                {{ "infosec.main.1.title2" | translate }}
              </div>
              <div
                class="testimonial-text mb-20"
                style="
                  color: #fff;
                  text-align: justify;
                  line-height: 30px;
                  padding: 0px 50px;
                "
              >
                <ul>
                  <li>{{ "infosec.main.1.1" | translate }}</li>
                  <li>{{ "infosec.main.1.2" | translate }}</li>
                  <li>{{ "infosec.main.1.3" | translate }}</li>
                  <li>{{ "infosec.main.1.4" | translate }}</li>
                  <li>{{ "infosec.main.1.5" | translate }}</li>
                  <li>{{ "infosec.main.1.6" | translate }}</li>
                  <li>{{ "infosec.main.1.7" | translate }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
