<section id="blog" class="blog-area pt-180">
  <div class="container about-area pb-80" *ngIf="loading">
    <div class="example">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
  </div>
  <div class="container about-area pb-80" *ngIf="page === 1">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "scoring.main.title" | translate
            }}</span>
          </h3>
          <p>{{ "scoring.main.desc" | translate }}</p>
          <p style="color: #2d66b1; font-size: 16px; padding-top: 4px">
            {{ "scoring.main.turul.title" | translate }}
          </p>
        </div>
      </div>
    </div>
    <!-- row -->
    <div
      class="row testimonial-right-content wow fadeIn mt-40"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-6">
        <div class="single-testimonial">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image-plus">
                <img src="assets/zeely/lock-alt.png" alt="author" />
              </div>
              <div class="testimonial-text" style="padding: 25px 0 0 25px">
                <p class="text" style="color: #2d66b1; font-weight: 600">
                  {{ "scoring.main.turul.1" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text">
            <p class="text" style="font-size: 20px">
              {{ "scoring.main.turul.1d" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="single-testimonial">
          <div class="d-sm-flex justify-content-between">
            <div class="author-info d-flex align-items-center">
              <div class="author-image-plus">
                <img src="assets/zeely/paper-plane.png" alt="author" />
              </div>
              <div class="testimonial-text" style="padding: 25px 0 0 25px">
                <p class="text" style="color: #2d66b1; font-weight: 600">
                  {{ "scoring.main.turul.2" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="testimonial-text">
            <p class="text" style="font-size: 20px">
              {{ "scoring.main.turul.2d" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row testimonial-right-content wow fadeIn mt-40"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <div class="single-testimonial">
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 30px;
              font-size: 20px;
              line-height: 36px;
              font-weight: 400;
            "
          >
            <span>{{ "scoring.main.desc2.1" | translate }}</span>
            <span class="pt-20 pb-20">{{
              "scoring.main.desc2.2" | translate
            }}</span>
            <span>{{ "scoring.main.desc2.3" | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <button
        *ngIf="isShown"
        class="main-btn mainbtn"
        (click)="start(); scrollToTop()"
      >
        Сэтгэл зүйн тест эхлүүлэх
      </button>
    </div>
    <div class="row justify-content-center mt-120">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "scoring.main.buteel.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="container mt-40" style="margin-bottom: 20px">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="our-services-image mt-50 wow fadeInLeftBig"
            style="text-align: center"
            data-wow-duration="1s"
            data-wow-delay="0.8s"
          >
            <img src="assets/zeely/scr-1.png" alt="service" />
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="our-services-content mt-150 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div
              style="font-size: 35px; font-weight: 600; padding-bottom: 20px"
            >
              <span style="padding-left: 20px; color: #2d66b1">{{
                "scoring.main.buteel.1" | translate
              }}</span>
            </div>
            <div
              class="single-service d-flex"
              style="padding: 10px 20px 10px 0"
            >
              <div class="service-content media-body">
                <p style="line-height: 26px; color: #fff">
                  {{ "scoring.main.buteel.1d" | translate
                  }}<a
                    href="https://www.mdpi.com/2076-328X/11/4/47?fbclid=IwAR0zn2uKnyrNrj231iJgtNNPoihFpo26gpgj8WqztjyMyD0bHY7Unlnne_s"
                    target="_blank"
                    >{{ "scoring.main.buteel.here" | translate }}</a
                  >
                  {{ "scoring.main.buteel.2dddd" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-100" style="margin-bottom: 20px">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="our-services-content mt-150 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div
              style="font-size: 35px; font-weight: 600; padding-bottom: 20px"
            >
              <span style="padding-left: 20px; color: #2d66b1">{{
                "scoring.main.buteel.2" | translate
              }}</span>
            </div>
            <div
              class="single-service d-flex"
              style="padding: 10px 20px 10px 0"
            >
              <div class="service-content media-body">
                <p style="line-height: 26px; color: #fff">
                  {{ "scoring.main.buteel.2d" | translate }}
                  <a
                    href="https://online.flippingbook.com/view/600297/?fbclid=IwAR1lyoFrjf1EjewECDajXiGH4nEuyHbm__fJoqlH_ntxrSIrkSUhMEGhH3c"
                    target="_blank"
                    >{{ "scoring.main.buteel.2dd" | translate }}</a
                  >
                  {{ "scoring.main.buteel.2ddd" | translate }}
                  <a
                    href="https://www.flipsnack.com/Dooko/-.html"
                    target="_blank"
                    >{{ "scoring.main.buteel.here" | translate }}</a
                  >
                  {{ "scoring.main.buteel.2dddd" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="our-services-image mt-50 wow fadeInRightBig"
            style="text-align: center"
            data-wow-duration="1s"
            data-wow-delay="0.8s"
          >
            <img src="assets/zeely/scr-2.png" alt="service" />
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-40" style="margin-bottom: 20px">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="our-services-image mt-50 wow fadeInLeftBig"
            style="text-align: center"
            data-wow-duration="1s"
            data-wow-delay="0.8s"
          >
            <img src="assets/zeely/scr-3.png" alt="service" />
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="our-services-content mt-150 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div
              style="font-size: 35px; font-weight: 600; padding-bottom: 20px"
            >
              <span style="padding-left: 20px; color: #2d66b1">{{
                "scoring.main.buteel.3" | translate
              }}</span>
            </div>
            <div
              class="single-service d-flex"
              style="padding: 10px 20px 10px 0"
            >
              <div class="service-content media-body">
                <p style="line-height: 26px; color: #fff">
                  {{ "scoring.main.buteel.3d" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container about-area pt-120">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div
            class="about-title text-center wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <h3 class="title">
              <span style="color: #2d66b1">{{
                "scoring.main.shiidel.title" | translate
              }}</span>
            </h3>
            <p>{{ "scoring.main.shiidel.desc" | translate }}</p>
          </div>
        </div>
      </div>
      <!-- row -->
      <div
        class="row testimonial-right-content wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.6s"
      >
        <div class="col-lg-4 mt-50">
          <div class="single-testimonial" style="height: 200px">
            <div class="d-sm-flex justify-content-between">
              <div class="author-info d-flex align-items-center">
                <div class="author-image-plus">
                  <img src="assets/zeely/doc.png" alt="author" />
                </div>
                <div class="testimonial-text" style="padding: 25px 0 0 25px">
                  <p class="text" style="color: #2d66b1; font-weight: 600"></p>
                </div>
              </div>
            </div>
            <div class="testimonial-text">
              <p class="text" style="font-size: 20px">
                {{ "scoring.main.shiidel.1" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-50">
          <div class="single-testimonial" style="height: 200px">
            <div class="d-sm-flex justify-content-between">
              <div class="author-info d-flex align-items-center">
                <div class="author-image-plus">
                  <img src="assets/zeely/emoji.png" alt="author" />
                </div>
                <div class="testimonial-text" style="padding: 25px 0 0 25px">
                  <p class="text" style="color: #2d66b1; font-weight: 600"></p>
                </div>
              </div>
            </div>
            <div class="testimonial-text">
              <p class="text" style="font-size: 20px">
                {{ "scoring.main.shiidel.2" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-50">
          <div class="single-testimonial" style="height: 200px">
            <div class="d-sm-flex justify-content-between">
              <div class="author-info d-flex align-items-center">
                <div class="author-image-plus">
                  <img src="assets/zeely/finger.png" alt="author" />
                </div>
                <div class="testimonial-text" style="padding: 25px 0 0 25px">
                  <p class="text" style="color: #2d66b1; font-weight: 600"></p>
                </div>
              </div>
            </div>
            <div class="testimonial-text">
              <p class="text" style="font-size: 20px">
                {{ "scoring.main.shiidel.3" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container about-area pt-100" style="text-align: center">
      <img src="assets/zeely/certificate1-big.png" alt="author" />
    </div>
  </div>

  <div
    class="container about-area pb-80"
    *ngIf="page === 2"
    (ngDoCheck)="scrollToTop()"
  >
    <nz-steps [nzCurrent]="current">
      <nz-step *ngFor="let step of resQuestions"></nz-step>
    </nz-steps>

    <div class="steps-content">
      <div class="qeus">
        <span>{{ "scoring.main.ask" | translate }} {{ current + 1 }}</span> -
        {{ resQuestions.length }}
      </div>
      <div class="qeus-text">{{ resQuestions[current].desc }}</div>
      <div
        class="ans-btn"
        (click)="next(ans.quesNo, ans.ansNo, resQuestions.length)"
        *ngFor="let ans of resQuestions[current].resAnswers"
      >
        {{ ans.ansText }}
      </div>
    </div>
  </div>
  <div class="container about-area pb-80" *ngIf="page === 3">
    <div class="steps-content testimonial-right-content">
      <div class="qeus">
        <span>{{ "scoring.main.result" | translate }}</span>
      </div>

      <div class="col-lg-12">
        <nz-collapse
          nzAccordion
          nzExpandIconPosition="right"
          nzBordered="false"
        >
          <nz-collapse-panel
            *ngFor="let res of resResults"
            class="ant-acc-header"
            nzHeader=" {{ res.scaleName }}"
            [nzActive]="true"
            [nzDisabled]="true"
            [nzShowArrow]="false"
          >
            <p
              style="
                font-size: 16px;
                line-height: 28px;
                color: #fff;
                text-align: left;
              "
            >
              {{ res.description }}
            </p>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <!-- <a
        target="_blank"
        href="https://khatan-suudal-invest-39936166.hubspotpagebuilder.com/hurungu-oruulalt"
      >
        <img
          src="assets/zeely/psyfint.png"
          alt="banner"
          style="margin-top: 50px"
        />
      </a> -->
    </div>
  </div>
</section>
